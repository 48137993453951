import MACHINE_TOP from "../../../assets/image/Game/LuckyMachine/machine-top.png";
import MACHINE_BOTTOM from "../../../assets/image/Game/LuckyMachine/machine-bottom.png";
import LOGO_FAMILY from "../../../assets/image/Game/LuckyMachine/logo-familymart.png";
import TWISTER from "../../../assets/image/Game/LuckyMachine/twister.png";
import GIFT from "../../../assets/image/Game/LuckyMachine/gift.png";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function LuckyMachine() {
  const [count, setCount] = useState(-1);
  const [start, setStart] = useState(false);
  const [isLasted, setIsLasted] = useState(false);
  const [countRound, setCountRound] = useState(0);
  const [indexGift, setIndexGift] = useState(0);
  const navigation = useNavigate();
  const { id } = useParams();
  const [segments, setsegments] = useState<any>([]);

  useEffect(() => {
    if (start) {
      const timer = setTimeout(() => {
        if (count < 9 && !isLasted) {
          if (count === 8) {
            setCountRound((prevCount) => prevCount + 1);
            setCount(0);
            if (countRound === 4) {
              setIsLasted(true);
            }
          } else {
            setCount((prevCount) => prevCount + 1);
          }
        } else if (isLasted) {
          if (count < indexGift) {
            setCount((prevCount) => prevCount + 1);
          }
          if (count === 8) {
            return () => clearTimeout(timer);
          }
          // setTimeout(() => {
          //   navigation(`/receive-code/${id}`);
          // }, 2000);
        }
      }, 125);
    }
  }, [count, isLasted, countRound, start]);
  function isEqual(obj1: any, obj2: any) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }
  const handleRun = () => {
    const positions = [];
    const data = JSON.parse(localStorage.getItem("WHEEL_PHANTHUONG") || "{}");
    console.log(data);
    for (let i = 0; i < segments.length; i++) {
      if (isEqual(segments[i]?.gift_code, data?.gift_code)) {
        positions.push(i);
      }
    }
    console.log(positions);
    setStart(true);
  };
  const rederArrayItem = () => {
    let items = [];
    for (let i = 0; i < 9; i++) {
      console.log(i);
      items.push(
        <div
          className="h-hull rounded-md relative"
          style={{ backgroundColor: count === i ? "#4D8AF8" : "#F0FBFE" }}
        >
          <img src={GIFT} className="absolute top-1/2 -translate-y-1/2" />
        </div>
      );
    }
    return items;
  };
  return (
    <div className="relative">
      <div>{count}</div>
      <div className="relative bg-machine">
        <div className="relative ">
          <img src={MACHINE_TOP} />
          <div className="absolute flex justify-between top-3 w-full px-5">
            <div>
              <img src={LOGO_FAMILY} />
            </div>
            <div>
              <img src={TWISTER} />
            </div>
          </div>
          <div className="absolute top-0 py-16 h-full px-8 w-full">
            <div className="grid grid-cols-3 h-full gap-1 text-center">
              {rederArrayItem()}
              {/* <div
                className="h-hull rounded-md relative"
                style={{ backgroundColor: "#4D8AF8" }}
              >
                <img src={GIFT} className="absolute top-1/2 -translate-y-1/2" />
              </div>
              <div
                className="h-hull rounded-md  relative"
                style={{ backgroundColor: "#F0FBFE" }}
              >
                <img src={GIFT} className="absolute top-1/2 -translate-y-1/2" />
              </div>
              <div
                className="h-hull rounded-md  relative"
                style={{ backgroundColor: "#F0FBFE" }}
              >
                <img src={GIFT} className="absolute top-1/2 -translate-y-1/2" />
              </div>
              <div
                className="h-hull rounded-md  relative"
                style={{ backgroundColor: "#F0FBFE" }}
              >
                <img src={GIFT} className="absolute top-1/2 -translate-y-1/2" />
              </div>
              <div
                className="h-hull rounded-md  relative"
                style={{ backgroundColor: "#F0FBFE" }}
              >
                <img src={GIFT} className="absolute top-1/2 -translate-y-1/2" />
              </div>
              <div
                className="h-hull rounded-md  relative"
                style={{ backgroundColor: "#F0FBFE" }}
              >
                <img src={GIFT} className="absolute top-1/2 -translate-y-1/2" />
              </div>
              <div
                className="h-hull rounded-md  relative"
                style={{ backgroundColor: "#F0FBFE" }}
              >
                <img src={GIFT} className="absolute top-1/2 -translate-y-1/2" />
              </div>
              <div
                className="h-hull rounded-md  relative"
                style={{ backgroundColor: "#F0FBFE" }}
              >
                <img src={GIFT} className="absolute top-1/2 -translate-y-1/2" />
              </div>
              <div
                className="h-hull rounded-md  relative"
                style={{ backgroundColor: "#F0FBFE" }}
              >
                <img src={GIFT} className="absolute top-1/2 -translate-y-1/2" />
              </div> */}
            </div>
          </div>
        </div>
        <div className="relative -top-[1px]">
          <img src={MACHINE_BOTTOM} />
          <div
            className="absolute top-10 w-full text-center"
            onClick={handleRun}
          >
            hello
          </div>
        </div>
      </div>
    </div>
  );
}
