export default function SkelatonRotation() {
  return (
    <div>
      <div
        className={`animate-pulse
         grid grid-cols-12 bg-[#e9e9e9] gap-2 shadow-item border-rotation mt-3 px-2 py-2 h-32 rounded-2xl relative mb-5
      `}
      >
        <div className="col-span-4">
          <div className="w-full bg-[#d4d4d4] h-full rounded-lg flex justify-center items-center">
            <svg
              className="w-10 h-10 text-gray-200 dark:text-gray-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 16 20"
            >
              <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
              <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
            </svg>
          </div>
        </div>
        <div className="col-span-8">
          <div className=" w-full h-6 rounded-2xl bg-[#d6d6d6]"></div>
          <div className=" w-10/12 mt-2 h-6 rounded-2xl bg-[#d6d6d6]"></div>
          <div className=" w-11/12 mt-2 h-6 rounded-2xl bg-[#d6d6d6]"></div>
          <div className="text-[12px] font-light-so">
            <span className="font-bold-so w-full h-10 bg-[#d6d6d6]"></span>
          </div>
        </div>
      </div>
    </div>
  );
}
