import POPUP from "../../assets/image/Popup/popup-point.png";
import OverlayPopup from "../OverlayPopup/OverlayPopup";

export default function PopupPoint({
  isShow,
  children,
}: {
  isShow: boolean;
  children: React.ReactNode;
}) {
  return (
    <div>
      <OverlayPopup isShow={isShow}>
        <div className="relative">
          <div className="relative">
            <img src={POPUP} />
            <div className="absolute top-1/2 -translate-y-1/2 flex flex-col w-full">
              {children}
            </div>
          </div>
        </div>
      </OverlayPopup>
    </div>
  );
}
