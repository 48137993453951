import QUESTION from "../../assets/image/GuideTakePhoto/question.png";
import { useNavigate, useParams } from "react-router-dom";
import {
  BACK_GLOBAL,
  userLocal,
} from "../../services/localService/localService";
import { useEffect } from "react";
import { setAuthorization } from "../../services/apiService/configURL";
import { GameServices } from "../../services/apiService/GameService";

export default function CarouselTakeAPhotoItem({ item }: { item: any }) {
  const navigation = useNavigate();
  let pathName = window.location.pathname;
  let { token } = userLocal.get();
  const { id } = useParams();

  setAuthorization(token);
  useEffect(() => {}, []);
  const handleNext = () => {
    localStorage.setItem(BACK_GLOBAL, "guide-take-photo");
    localStorage.setItem("STATUS_CONFIRM", "FALSE");
    GameServices.ListRotation("", 1, 10)
      .then((res: any) => {
        console.log(res);
        navigation(`/guide-take-photo/${id}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="relative top-10 z-0">
      <div className="flex justify-center">
        <img
          src={item.url}
          style={{
            width: "320px",
          }}
          className="rounded-2xl bg-center bg-cove duration-500"
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "90%",
          marginTop: "5px",
        }}
      >
        <div
          className="title-gu font-semibold-mon w-full"
          style={{ marginTop: "0px", fontSize: "12px" }}
        >
          <div className="shadow-item relative left-1/2 -translate-x-[45%] ml-1 w-full mt-20 borderguide rounded-xl pt-3 pb-8">
            <div className="uppercase text-[21px] text-center px-5 text-[#4E9ABB] font-semibold-so">
              hướng dẫn chụp hóa đơn
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: item.title,
              }}
              className="text-[14px] bold-text-html text-center font-light-so mt-2 px-5"
            ></div>
            <div className="px-5 mt-2">
              <div className="border-luuy bg-[#FFFAF0] px-3 py-3 rounded-lg">
                <div className="text-left text-[16px] text-[#F7B119] flex">
                  <div className="w-5 mr-1">
                    <img src={QUESTION} className="w-5" />
                  </div>{" "}
                  <span className="-mt-1">Lưu ý</span>
                </div>
                <div className="font-light-so text-[14px] text-left">
                  Không gấp hoặc che các thông tin ở phần đầu và cuối hóa đơn
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  className="bg-[#5BC2DC] relative z-90 shadow-item w-44 mt-4 px-5 font-semibold-so py-3 text-[16px] rounded-lg text-white"
                  onClick={handleNext}
                >
                  Bỏ qua hướng dẫn
                </button>
              </div>
            </div>
          </div>
          <div className="h-20"></div>
        </div>
      </div>
    </div>
  );
}
