import BackgroundGame from "./BackgroundGame/BackgroundGame";
import StartLoading from "./Loading/StartLoading";
import TOP20 from "../../../assets/image/Game/PlantTree/top20.png";
import MISSIONDAY from "../../../assets/image/Game/PlantTree/missionday.png";
import PRIZE from "../../../assets/image/Game/PlantTree/prize.png";
import BUTTON from "../../../assets/image/Game/PlantTree/button-join.png";
import WATERING_CAN from "../../../assets/image/Game/PlantTree/watering-can.png";
import TREE from "../../../assets/image/Game/PlantTree/big-tree.png";
import { useEffect, useState } from "react";
import Tree from "./Tree/Tree";
import Water from "./Loading/Water";
import GIFT from "../../../assets/image/Game/PlantTree/gift.png";
import PopupRatings from "../../../components/Popup/GameLuckyMachine/PopupRatings";

export default function PlayGame() {
  const [isOpenCan, setIsOpenCan] = useState(false);
  const [isWaterRun, setIsWaterRun] = useState(false);
  const [isDisableCan, setIsDisableCan] = useState(true);
  const [showTab, setShowTab] = useState(false);
  const [total, setTotal] = useState(8);
  const [current, setCurrent] = useState(5);
  const handleOpenStatus = () => {
    setIsOpenCan(true);
    setTimeout(() => {
      setCurrent(current + 1);
      setIsWaterRun(true);
    }, 2200);
    setTimeout(() => {
      setIsOpenCan(false);
      setIsDisableCan(false);
    }, 4000);
  };
  useEffect(() => {
    setTimeout(() => {
      setShowTab(true);
    }, 3000);
  }, []);
  return (
    <BackgroundGame>
      <div className="relative w-full text-center top-[61vh] -translate-y-1/2">
        <Tree tree={TREE} />

        {!showTab ? (
          <StartLoading width={100} title="loading ..." gift={""} />
        ) : (
          <StartLoading
            width={(current / total) * 100}
            title={`Còn ${current}/${total} xô nước nữa`}
            gift={GIFT}
          />
        )}
        <div
          className={`${
            showTab ? "showTab" : "hidden"
          } absolute -bottom-[150px] w-full`}
        >
          <div className="grid grid-cols-9 gap-2">
            <div className="col-span-2 flex items-end">
              <div>
                <img src={TOP20} />
              </div>
            </div>
            <div className="col-span-2 flex items-end">
              <div>
                <img src={MISSIONDAY} />
              </div>
            </div>
            <div className="col-span-2 flex items-end">
              <div>
                <img src={PRIZE} />
              </div>
            </div>
            <div className="col-span-3 relative flex justify-center">
              <div className="w-full h-[100px] relative">
                <div className="absolute bottom-3 left-1/2 -translate-x-1/2 w-[100px] h-[100px] bg-watering-can"></div>
                <div className="absolute bottom-6 w-[100px]">
                  <div
                    className={`${
                      isOpenCan ? "animation-wateringcan z-20" : ""
                    } relative rotation-wateringcan`}
                    onClick={handleOpenStatus}
                  >
                    {isDisableCan && (
                      <img
                        src={WATERING_CAN}
                        className="w-[100px] relative z-20"
                      />
                    )}
                    {isWaterRun && <Water />}
                  </div>
                </div>
                <div className="absolute bottom-0 w-full">
                  <img src={BUTTON} />
                  <div className="absolute font-regular-so left-1/2 -translate-x-1/2 top-[45%] -translate-y-1/2 w-full text-white">
                    X2 xô nước
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopupRatings />
    </BackgroundGame>
  );
}
