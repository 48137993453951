import TEN_LUA from "../../assets/image/Popup/tenlua.png";
import LoadingButton from "../Loading/LoadingButton";
import PHONE from "../../assets/image/Popup/phone.png";
import ContactPhoneZalo from "../ContactPhoneZalo/ContactPhoneZalo";
import { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

export default function Waiting() {
  const { hotline } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  const [percent, setPercent] = useState(0);
  const [count, setCount] = useState(0);
  useEffect(() => {
    // const percentCount = setInterval(() => {
    //   setPercent(percent + 1);
    //   if (percent === 100) {
    //     setPercent(0);
    //     clearInterval(percentCount);
    //   }
    // }, 600);
  });
  // const [count, setPercent] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPercent((prevCount) => {
        if (prevCount >= 100) {
          setCount(count + 1);
          clearInterval(interval);
          return 100;
        }
        return prevCount + 1;
      });
    }, 50); // 80 milliseconds (0.08 seconds)

    return () => clearInterval(interval);
  }, []);
  const renderTime = ({ remainingTime }: any) => {
    if (remainingTime === 0) {
      return (
        <div className="timer text-[#4E9ABB] font-semibold-so text-[36px] font-bold-so">
          100%
        </div>
      );
    }
    return (
      <div className="timer">
        <span className="countdown-text text-[#4E9ABB] text-[36px] font-bold-so flex justify-center">
          {" "}
          <div className="text-center flex justify-center">{percent}%</div>
        </span>
      </div>
    );
  };
  return (
    <div className="bg-coundown min-h-screen relative">
      <div className="absolute top-1/2 -translate-y-1/2 flex flex-col items-center ">
        <div className="font-bold-so text-[#4E9ABB] text-center flex">
          Hóa đơn đang được tải lên{" "}
          <div className="flex space-x-1 justify-center mt-4">
            <span className="sr-only">Loading...</span>
            <div className="h-1 w-1 bg-[#4E9ABB] rounded-full animate-bounce [animation-delay:-0.01s]"></div>
            <div className="h-1 w-1 bg-[#4E9ABB] rounded-full animate-bounce [animation-delay:-0.15s]"></div>
            <div className="h-1 w-1 bg-[#4E9ABB] rounded-full animate-bounce"></div>
          </div>
        </div>
        <div className="flex justify-center mt-10">
          <img src={TEN_LUA} className="w-10/12" />
        </div>
        <div className="font-regular-so text-[#424242] text-[14px] text-center px-5">
          Vui lòng đợi trong giây lát và đừng rời khỏi màn hình. Hệ thống đang
          xử lý
        </div>
        <div className="mt-16">
          <CountdownCircleTimer
            isPlaying
            duration={5}
            colors={["#4E9ABB", "#4E9ABB", "#4E9ABB", "#4E9ABB"]}
            colorsTime={[5, 6, 3, 0]}
            onComplete={() => ({ shouldRepeat: false, delay: 1 })}
          >
            {renderTime}
          </CountdownCircleTimer>
        </div>
        <div className="flex items-center mt-20">
          <div>
            <img src={PHONE} />
          </div>
          <div className="text-[#2D4E68] font-light-so ml-2">
            Liên hệ Hotline{" "}
            <span className="font-semibold-so">
              <a href={`tel:${hotline}`}>{hotline}</a>
            </span>
          </div>
        </div>
      </div>
      <ContactPhoneZalo />
    </div>
  );
}
