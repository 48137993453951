import BILL from "../../../assets/image/GuideTakePhoto/bill.png";
import ContactPhoneZalo from "../../../components/ContactPhoneZalo/ContactPhoneZalo";
import PHONE from "../../../assets/image/Popup/phone.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CANCEL from "../../../assets/image/Home/cancel-popup.png";

export default function BillInValid() {
  const { hotline } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  const REVIEW_IMG = localStorage.getItem("REVIEW_IMG");
  console.log(REVIEW_IMG);
  const navigation = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleHome = () => {
    navigation("/");
    localStorage.removeItem("REVIEW_IMG");
  };
  const handleReview = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <div className="">
      {isOpen ? (
        <div className="relative">
          <img src={REVIEW_IMG as string} className="w-full" />
          <div
            className="absolute bottom-10 left-1/2 -translate-x-1/2"
            onClick={handleClose}
          >
            <img src={CANCEL} />
          </div>
        </div>
      ) : (
        <div className="px-5 pt-10 text-center bg-coundown min-h-screen relative">
          <div className="text-[#4E9ABB] text-[24px] font-semibold-so">
            {/* Hóa đơn không hợp lệ */}
          </div>
          <div className="flex justify-center">
            <img src={BILL} />
          </div>
          <div className="font-light-so">
            Hình ảnh của bạn không đủ thông tin cần thiết, xin vui lòng kiểm tra
            lại
          </div>
          <div className="grid grid-cols-2 gap-2 mt-10">
            <button
              className="px-1 py-3 text-white bg-[#5BC2DC] rounded-xl text-center border-first shadow-item"
              onClick={handleReview}
            >
              Xem lại hóa đơn
            </button>
            <button
              className="px-1 py-3 text-white bg-[#2D4E68] rounded-xl text-center border-first shadow-item"
              onClick={handleHome}
            >
              Trở về trang chủ
            </button>
          </div>
          <div className="absolute bottom-20 flex items-center justify-center left-1/2 w-full -translate-x-1/2 mt-20">
            <div>
              <img src={PHONE} />
            </div>
            <div className="text-[#2D4E68] font-light-so ml-2">
              Liên hệ Hotline{" "}
              <span className="font-semibold-so">
                <a href={`tel:${hotline}`}>{hotline}</a>
              </span>
            </div>
          </div>
        </div>
      )}
      <ContactPhoneZalo />
    </div>
  );
}
