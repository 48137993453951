import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HomePageModel } from "../../models/HomePage";
import { homeServices } from "../../services/apiService/homeService";
import { homeLocal } from "../../services/localService/localService";

const initialState = {
  homeData: homeLocal.get() || "",
} as any;

export const getAllBannerHome = createAsyncThunk("home/getHome", async () => {
  const res = await homeServices.homeApi();
  return res;
});

const homeReducer = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build.addCase(getAllBannerHome.pending, (state, action) => {});
    build.addCase(
      getAllBannerHome.fulfilled,
      (state: HomePageModel, action: PayloadAction<any>) => {
        homeLocal.set(action.payload);
        return { ...state, homeData: action.payload };
      }
    );
    build.addCase(
      getAllBannerHome.rejected,
      (state: HomePageModel, action: PayloadAction<any>) => {
        return { ...state };
      }
    );
  },
});
export const homeBanner = (state: any) => state.homeSlice;

export default homeReducer.reducer;
