import { Fragment } from "react/jsx-runtime";
import ChooseHome from "../../../components/NavChoosePageHome/ChooseHome";
import ItemGiftHomePage from "./ItemGiftHomePage";
import ICON_GIFT from "../../../assets/image/animation-gift.gif";
import { ExchangeGift, Gifts } from "../../../models/HomePage";
import Carousel from "react-multi-carousel";

export default function ChangeGiftPhysical({
  lstExchangeGift,
}: {
  lstExchangeGift: any;
}) {
  return (
    <div className="px-5">
      <div className="mt-3">
        <ChooseHome
          value="Xem tất cả"
          icon={ICON_GIFT}
          name="ĐỔI ĐIỂM"
          navigate="/exchange-point"
        />
      </div>
      <div className="mt-2 mb-6">
        <div className="">
          {lstExchangeGift?.exchange_gifts &&
            lstExchangeGift?.exchange_gifts?.map(
              (res: ExchangeGift, index: number) => (
                <div key={index} className="mt-3">
                  <div className="text-[#2D4E68] font-semibold-so border-title-exchangegift">
                    {res.level_name}
                  </div>
                  <div className="overhand">
                    {res?.gifts && (
                      <>
                        {res?.gifts?.map((resGift: Gifts) => (
                          <div key={resGift.gift_id}>
                            <ItemGiftHomePage
                              gift_vendor_logo_url={
                                resGift?.gift_vendor_logo_url
                              }
                              gift_id={resGift?.gift_id}
                              reward_image_url={resGift?.reward_image_url}
                              gift_image_url={resGift?.gift_image_url}
                              gift_name={resGift?.gift_name}
                              required_points={resGift?.required_points}
                              reward_id={resGift?.reward_id}
                            />
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              )
            )}
        </div>
      </div>
    </div>
  );
}
