import DAY_GAP from "../../../assets/image/Game/Pickup/day-gap.png";
import GAP from "../../../assets/image/Game/Pickup/gap.png";
import OPEN_GAP from "../../../assets/image/Game/Pickup/open_gap.png";
export default function ActiveCatcher({
  active_pickup,
  gap_active,
  open_gap,
  img,
}: any) {
  return (
    <div className="relative ani-day-gap">
      <img
        src={DAY_GAP}
        loading="lazy"
        className="relative day-gap z-0"
        alt="Image"
      />
      <div className="rotate-gap">
        {open_gap ? (
          <div className="w-20">
            <img src={OPEN_GAP} loading="lazy" width={80} alt="Image" />
          </div>
        ) : (
          <img src={GAP} loading="lazy" width={160} alt="Image123" />
        )}

        {gap_active ? (
          <img
            src={img}
            loading="lazy"
            className="absolute left-2 top-8 w-11 rotate-left"
            alt="Image456+"
          />
        ) : null}
      </div>
    </div>
  );
}
