import Navbar from "../../components/NavbarMenu/Navbar";
import MenuTab from "../../components/MenuTab/MenuTab";
import { useEffect, useState } from "react";
import ItemGift from "./ItemGift";
import BACK_HEADER from "../../assets/image/Header/back-header.png";
import { ListReceiptServices } from "../../services/apiService/ListReceiveService";
import { CodeGift, PageGift, PrizeList } from "../../models/GiftModel";
import { Pagination } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { userLocal } from "../../services/localService/localService";
import { setAuthorization } from "../../services/apiService/configURL";
import HeaderFrame from "../../components/HeaderFrame/HeaderFrame";
import SkelatonHistory from "../../components/Skelaton/SkelatonHistory";
import Menu3Tab from "../../components/MenuTab/Menu3Tab";
import ItemCode from "./ItemCode";
import ContactPhoneZalo from "../../components/ContactPhoneZalo/ContactPhoneZalo";
import PopupLogin from "../../popup/PopupLogin";
import CLOSE from "../../assets/image/Home/cancel-popup.png";

export default function ListGift() {
  const { current } = useParams();
  const navigation = useNavigate();
  const [currents, setCurrent] = useState<string>(current ? current : "1");
  const [page, setPage] = useState<number>(1);
  const [reward_type, setRewardType] = useState<string>("voucher");
  const [listPage, setListPage] = useState<PageGift>();
  const [listCodes, setListCodes] = useState<any>();
  const [checkPage, setCheckPage] = useState<number>(0);
  const [codePage, setCodePage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let { token } = userLocal.get();
  setAuthorization(token);
  const handleClick = (e: any, status_gifts: string) => {
    console.log(e.target.id);
    console.log(status_gifts);
    setCurrent(e.target.id);
    setPage(1);
    if (e.target.id === "1") {
      setRewardType("voucher");
      getData(1, "voucher");
    } else if (e.target.id === "2") {
      setRewardType("physical");
      getData(1, "physical");
    } else {
      setRewardType("physical");
      getCodes(1, "physical");
    }
  };
  useEffect(() => {
    setIsLoading(true);
    if (currents === "1") {
      setRewardType("voucher");
      console.log("test");
      getData(page, "voucher");
    } else if (currents === "2") {
      setRewardType("");
      getData(page, "physical");
    } else {
      setRewardType("physical");
      getCodes(1, "physical");
    }
    ListReceiptServices.UpdateStatusGift()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    localStorage.removeItem("GAME_DATA_LOCAL");
    localStorage.removeItem("LUOT_QUAY");
    localStorage.removeItem("SALE_ID");
  }, []);
  const getCodes = (page: number, reward_type: string) => {
    const data = {
      page: page,
      limit: 10,
    };
    ListReceiptServices.GetCodes(data)
      .then((res: any) => {
        console.log(res);
        setListCodes(res);

        setCodePage(res.total_item);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getData = (page: number, reward_type: string) => {
    const data = {
      page: page,
      limit: 10,
      reward_type: reward_type,
    };
    setIsLoading(true);
    ListReceiptServices.GetListMyGift(data)
      .then((res: any) => {
        console.log(res);
        setListPage(res);

        setCheckPage(res.total_item);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleD = (data: number) => {
    console.log(data);
    setPage(data);
    getData(data, reward_type);
  };
  const handleDCode = (data: number) => {
    console.log(data);
    document.body.style.overflow = "unset";
    setPage(data);
    getCodes(data, reward_type);
  };
  // console.log(currents);
  const [isPopup, setIsPopup] = useState(false);
  const [isDisableNavBar, setIsDisableNavBar] = useState(false);
  const [noti, setNoti] = useState("");
  const handleClose = () => {
    document.body.style.overflow = "unset";
    setIsPopup(false);
  };
  return (
    <div className="">
      {isPopup && (
        <PopupLogin isShow={isPopup}>
          <div>
            <div className="">
              <div className="text-[24px] text-[#2D4E68] font-semibold-so pt-5 uppercase">
                Thông báo
              </div>
            </div>
            <div className="pt-5 pb-10">{noti}</div>
            <div className="absolute -bottom-20 left-1/2 -translate-x-1/2">
              <img src={CLOSE} onClick={handleClose} />
            </div>
          </div>
        </PopupLogin>
      )}
      <HeaderFrame
        title="Quà của tôi"
        iconBack={BACK_HEADER}
        handleBack={() => {
          navigation("/");
          document.body.style.overflow = "unset";
        }}
        footer=""
      >
        <div className="relative top-5 px-2">
          <Menu3Tab
            current={currents}
            handleClick={(e: any, status_gifts: string) =>
              handleClick(e, status_gifts)
            }
            nameLeft="Voucher"
            nameCenter="Quà Vật Lý"
            nameRight="Mã dự thưởng"
          />
          <div className="mt-2">
            {!isLoading ? (
              <>
                {currents !== "3" && (
                  <div>
                    {(listPage?.prize_list?.length as number) === 0 ? (
                      <div className="relative top-20 z-50 text-center font-light-so min-h-[280px]">
                        Quý khách hãy tham gia ngay các chương trình khuyến mãi
                        để nhận được những phần quà hấp dẫn!
                      </div>
                    ) : (
                      listPage?.prize_list?.map(
                        (res: PrizeList, index: number) => (
                          <div key={index}>
                            <ItemGift
                              data={res}
                              current={currents}
                              setIsPopup={setIsPopup}
                              setNoti={setNoti}
                              setIsDisableNavBar={setIsDisableNavBar}
                            />
                          </div>
                        )
                      )
                    )}
                    <ContactPhoneZalo />
                  </div>
                )}
                {currents === "3" && (
                  <div>
                    {(listCodes?.code_list?.length as number) === 0 ? (
                      <div className="relative top-10 text-center font-light-so">
                        Quý khách hãy tham gia ngay các chương trình khuyến mãi
                        để nhận được những phần quà hấp dẫn!
                      </div>
                    ) : (
                      listCodes?.code_list?.map(
                        (res: CodeGift, index: number) => (
                          <div key={index}>
                            <ItemCode data={res} />
                          </div>
                        )
                      )
                    )}
                    <ContactPhoneZalo />
                  </div>
                )}
              </>
            ) : (
              <div>
                <SkelatonHistory />
                <SkelatonHistory />
                <SkelatonHistory />
                <SkelatonHistory />
                <SkelatonHistory />
                <SkelatonHistory />
              </div>
            )}
          </div>
          {/* <div className="h-24"></div> */}

          {currents !== "3" && (
            <div className="mb-32 mt-5 flex justify-center">
              {checkPage > 10 && (
                <Pagination
                  current={page}
                  total={listPage?.total_item}
                  onChange={handleD}
                />
              )}
            </div>
          )}
          {currents === "3" && (
            <div className="mb-32 mt-5 flex justify-center">
              {codePage > 10 && (
                <Pagination
                  current={page}
                  total={listCodes?.total_item}
                  onChange={handleDCode}
                />
              )}
            </div>
          )}
        </div>
      </HeaderFrame>
      {!isDisableNavBar && <Navbar />}
    </div>
  );
}
