import HeaderBack from "../../../components/HeaderBack/HeaderBack";
import { locationBefore } from "../../../services/localService/localService";
import BACK_HEADER from "../../../assets/image/Header/back-header.png";
import { useState } from "react";
import CU from "../../../assets/image/DetailsRating/cu.png";
import SILVER from "../../../assets/image/DetailsRating/silver.png";
import GOLD from "../../../assets/image/DetailsRating/gold.png";
import VIP from "../../../assets/image/DetailsRating/vip.png";
import LoyaltyCards from "../../../components/LoyaltyCards/LoyaltyCards";
import BG_RANK from "../../../assets/image/AccumulatePoint/bg-ranks.png";
import ICON_GIFT from "../../../assets/image/Home/icon-gift.png";
import DOLAR from "../../../assets/image/AccumulatePoint/dolar.png";

export default function DetailsRating() {
  const location = locationBefore.get();
  const [current, setCurrent] = useState<string>("1");
  const [sliderValue, setSliderValue] = useState(1600);
  const [totalCoin, setTotalCoin] = useState(2000);

  function handleChange(e: any) {
    setSliderValue(e.value);
  }
  const hanldeClick = (e: any, status_gifts: string) => {
    console.log(e.target);
    console.log(status_gifts);
    setCurrent(status_gifts);
  };

  return (
    <div>
      <div className="px-5">
        <HeaderBack
          name="Chi tiết hạng"
          navigate={location}
          imageBack={BACK_HEADER}
        />
      </div>
      <div className="px-5">
        <div className="tab-rating flex h-16 items-center justify-between align-middle">
          <div className="flex flex-auto justify-center">
            <button
              key="1"
              id="1"
              disabled={current === "1"}
              onClick={(e) => hanldeClick(e, "1")}
              className="w-[60px]"
            >
              <div>
                <img src={CU} className="w-full" />
              </div>
            </button>
          </div>
          <div className="flex flex-auto justify-center">
            <button
              key="2"
              id="2"
              disabled={current === "2"}
              onClick={(e) => hanldeClick(e, "2")}
              className="w-[60px]"
            >
              <div>
                <img src={SILVER} className="w-full" />
              </div>
            </button>
          </div>
          <div className="flex flex-auto justify-center">
            <button
              key="3"
              id="3"
              disabled={current === "3"}
              onClick={(e) => hanldeClick(e, "3")}
              className="w-[60px]"
            >
              <div>
                <img src={GOLD} className="w-full" />
              </div>
            </button>
          </div>
          <div className="flex flex-auto justify-center">
            <button
              key="4"
              id="4"
              disabled={current === "4"}
              onClick={(e) => hanldeClick(e, "4")}
              className="w-[60px]"
            >
              <div>
                <img src={VIP} className="w-full" />
              </div>
            </button>
          </div>
        </div>
        <div className="relative top-5 bg-[#F0F0F0]">
          <div>
            <LoyaltyCards
              imageBackground={BG_RANK}
              coin={sliderValue}
              totalCoin={totalCoin}
              handleChange={(e: any) => handleChange(e)}
            />
          </div>
          <div className="absolute bg-[#F0F0F0] w-full px-4 pb-4 rounded-b-3xl">
            <div className="text-[20px] mt-1 text-[#4E9ABB] font-regular-so">
              Ưu đãi đặc quyền
            </div>
            <div className="mt-2 grid grid-cols-12">
              <div className="w-8 h-8 bg-[#F5F9FF] flex justify-center items-center rounded-3xl col-span-1">
                <img src={ICON_GIFT} className="w-5 h-5" />
              </div>
              <div className="col-span-11 ml-3 text-[13px] leading-4">
                <div>Đổi quà hấp dẫn</div>
                <div className="text-[10px] font-light-so text-[#b1afaf]">
                  Sử dụng điểm để đổi quà, voucher ...
                </div>
              </div>
            </div>
            <div className="mt-2 grid grid-cols-12">
              <div className="w-8 h-8 bg-[#F5F9FF] flex justify-center items-center rounded-3xl col-span-1">
                <img src={DOLAR} className="w-5 h-5" />
              </div>
              <div className="col-span-11 ml-3 text-[13px] leading-4">
                <div>Thu nhập điểm vào ngày sinh nhật x1</div>
                <div className="text-[10px] font-light-so text-[#b1afaf]">
                  Tương tác, chơi game để nhận điểm
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
