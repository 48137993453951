import CARD from "../../../assets/image/Game/FlipCard/card-6.png";
import TEST from "../../../assets/image/Game/FlipCard/test.png";

interface CardModel {
  id: number;
  name: string;
  flipped: string;
  matched: string;
  clicked: any;
}
const Card = ({ id, name, flipped, matched, clicked }: CardModel) => {
  return (
    <div
      onClick={() => (flipped ? undefined : clicked(name, id))}
      className={
        "card col-span-1" +
        (flipped ? " flipped" : "") +
        (matched ? " matched" : "")
      }
    >
      <div className="back">
        <img src={CARD} className="w-full" />
      </div>
      <div className="front1">
        <div className=" flex justify-center items-center opacity-90 relative">
          <div className="relative">
            <img alt={name} src={TEST} className="w-full" />
          </div>
          <div className="absolute flex justify-center items-center">
            <img src={name} className="w-14" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
