import BACKGROUND from "../../../assets/image/Game/PeerJumpTower/background.png";
import OverlayPopup from "../../../components/OverlayPopup/OverlayPopup";
import TITLE_RESULT from "../../../assets/image/Game/PeerJumpTower/title-result.png";
import GIFT from "../../../assets/image/Game/PeerJumpTower/gift.png";
import BTN_RESULT from "../../../assets/image/Game/PeerJumpTower/button-result.png";
import { useEffect, useState } from "react";
import LoadingButton from "../../../components/Loading/LoadingButton";
import { useNavigate, useParams } from "react-router-dom";
import videos from "../../../assets/image/up.mp4";

export default function PeerReceive() {
  const [isLoading, setIsLoading] = useState(false);
  const [video, setVideo] = useState(true);

  const luot_quay = localStorage.getItem("LUOT_QUAY");
  const navigation = useNavigate();
  const { campaign_id, sale_id } = useParams<string>();
  const confirm = localStorage.getItem("CONFIRM");
  useEffect(() => {
    if (confirm !== "CONFIRM") {
      navigation("/games");
    }
    setTimeout(() => {
      setVideo(false);
    }, 3500);
  }, []);
  const handleSubmit = () => {
    console.log(1);
    localStorage.removeItem("CONFIRM");
    setIsLoading(true);
    if (luot_quay === "0") {
      navigation("/list-gift");
    } else {
      navigation(`/bear_pickup/${campaign_id}/${sale_id}`);
    }
  };
  const gift_wheel = JSON.parse(localStorage.getItem("LUCKY_CODE") || "{}");

  const gift = JSON.parse(localStorage.getItem("GIFT_WHEEL") || "{}");
  const game_data = JSON.parse(localStorage.getItem("GAME_DATA_LOCAL") || "{}");
  const { hotline } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  return (
    <div>
      {video ? (
        <div className="stylevideo">
          <video
            width="100%"
            height="auto"
            autoPlay
            muted
            loop
            playsInline
            controls={false} // Removes the control bar to prevent pausing
            style={{ display: "block", maxWidth: "100%" }}
          >
            <source src={videos} type="video/mp4" />
          </video>
        </div>
      ) : (
        <>
          <div>
            <img src={BACKGROUND} />
          </div>

          <OverlayPopup isShow={true}>
            <div className="w-full">
              <div className="w-full flex justify-center">
                {gift?.no_gift ? (
                  <img
                    src={game_data?.title_no_gift_image_url}
                    className="w-10/12"
                  />
                ) : (
                  <img
                    src={game_data?.title_result_image_url}
                    className="w-10/12"
                  />
                )}
              </div>
              <div className="text-[18px] text-white font-regular-so">
                {!gift?.no_gift && gift?.gift_name}
              </div>
              {!gift?.no_gift && (
                <div className="text-[9px] text-white font-light-so">
                  {gift_wheel?.exchange_code}
                </div>
              )}

              <div className="w-full flex justify-center">
                <img src={gift?.gift_reward_image_url} className="w-9/12" />
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: gift?.instructions_redeeming_rewards,
                }}
                className="text-white font-light-so"
              ></div>
              <div
                className="flex justify-center mt-5 relative"
                onClick={isLoading ? () => {} : handleSubmit}
              >
                <div className="relative">
                  <img src={BTN_RESULT} />
                </div>
                {isLoading ? (
                  <div className="absolute top-1/2 -translate-y-1/2">
                    <LoadingButton />
                  </div>
                ) : (
                  <div className="absolute text-white top-0 font-light-so">
                    {luot_quay === "0" ? (
                      <div className="mt-1">
                        Bạn{" "}
                        <span className="text-[#1F3D1C] font-bold-so text-[18px]">
                          Hết
                        </span>{" "}
                        lượt chơi
                      </div>
                    ) : (
                      <>
                        Còn{" "}
                        <span className="text-black text-[20px] font-semibold-so">
                          {luot_quay}
                        </span>{" "}
                        lượt chơi
                      </>
                    )}
                    {luot_quay === "0" ? (
                      <div className="text-[14px]">TRỞ VỀ TRANG CHỦ</div>
                    ) : (
                      <div>TIẾP TỤC</div>
                    )}
                  </div>
                )}
              </div>
              <div className="relative top-5 text-white">
                Tổng đài <a href={`tel:${hotline}`}>{hotline}</a>
              </div>
            </div>
          </OverlayPopup>
        </>
      )}
    </div>
  );
}
