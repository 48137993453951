import BACK from "../../../assets/image/Header/back-header.png";
import GUIDE_URGENT from "../../../assets/image/GuideTakePhoto/guide-urgent.png";
import { useNavigate } from "react-router-dom";

export default function UrgentPageReview() {
  const navigation = useNavigate();
  const handleRedirectHome = () => {
    navigation("/");
  };
  return (
    <div>
      <div className="mt-5 px-5">
        <img src={BACK} className="w-9" onClick={handleRedirectHome} />
        <div className="text-center text-[#4E9ABB] font-semibold-so text-[24px]">
          HÓA ĐƠN CỦA BẠN <br />
          ĐANG ĐƯỢC ƯU TIÊN DUYỆT
        </div>
        <div className="text-[#2A2A2A] text-center font-regular-so">
          Vui lòng xem hướng dẫn bên dưới để tiếp tục tham gia
        </div>
        <div>
          <img src={GUIDE_URGENT} className="w-full" />
          <div className="bg-[#f8feff] text-center py-8 rounded-xl shadow-item">
            <div className="text-[#4E9ABB] text-[24px] font-semibold-so">
              TRỞ LẠI TRANG CHỦ
            </div>
            <div className="font-light-so mt-3">
              Sau khi hóa đơn được duyệt, Quý khách vào mục{" "}
              <span className="font-semibold-so">Chơi Game nhận quà </span>
              và mục <span className="font-semibold-so">Quà của tôi</span> để
              nhận thưởng
            </div>
            <div className="mt-4">
              <button
                className="bg-[#5BC2DC] text-white py-3 px-4 rounded-xl shadow-item"
                onClick={handleRedirectHome}
              >
                Bỏ qua hướng dẫn
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
