export default function Water() {
  return (
    <div className="absolute rotation-water -left-10 top-14">
      <div className="absolute animation-waterrun top-0 w-3 h-3 bg-[#DEFAFD] rounded-b-full rounded-tl-full rotation-water-item"></div>
      <div className="absolute animation-waterrun top-4 left-3 w-3 h-3 bg-[#DEFAFD] rounded-b-full rounded-tl-full rotation-water-item"></div>
      <div className="absolute animation-waterrun top-1 left-5 w-3 h-3 bg-[#DEFAFD] rounded-b-full rounded-tl-full rotation-water-item"></div>
      <div className="absolute animation-waterrun -top-7 right-3 w-3 h-3 bg-[#DEFAFD] rounded-b-full rounded-tl-full rotation-water-item"></div>
      <div className="absolute animation-waterrun -top-1 right-1 w-3 h-3 bg-[#DEFAFD] rounded-b-full rounded-tl-full rotation-water-item"></div>
      <div className="absolute animation-waterrun -top-8 -right-4 w-3 h-3 bg-[#DEFAFD] rounded-b-full rounded-tl-full rotation-water-item"></div>
      <div className="absolute animation-waterrun top-6 -right-0 w-3 h-3 bg-[#DEFAFD] rounded-b-full rounded-tl-full rotation-water-item"></div>
      <div className="absolute animation-waterrun -top-3 left-4 w-3 h-3 bg-[#DEFAFD] rounded-b-full rounded-tl-full rotation-water-item"></div>
      <div className="absolute animation-waterrun top-4 right-6 w-3 h-3 bg-[#DEFAFD] rounded-b-full rounded-tl-full rotation-water-item"></div>
    </div>
  );
}
