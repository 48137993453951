import { useNavigate, useParams } from "react-router-dom";
import BACK from "../../assets/image/Header/back-header.png";
import { useEffect, useState } from "react";
import "../../assets/css/ListReceiveCustomer.css";
import { ListReceiptServices } from "../../services/apiService/ListReceiveService";
import { Pagination } from "antd";
import TabGift from "./TabGift";
import TabReward from "./TabReward";
import {
  ListGiftCustomer,
  ListWinCustomer,
} from "../../models/ListReceiveCustomer";
import HeaderFrame from "../../components/HeaderFrame/HeaderFrame";

export default function ListReceiveCustomer() {
  const navigation = useNavigate();
  const { id, type } = useParams();
  const [page, setPage] = useState<number>(1);
  const [current, setCurrent] = useState("1");
  const [lstGift, setDataListGift] = useState([]);
  const [totalPrize, setTotalPrize] = useState<number>(0);
  const [listWin, setDataListWin] = useState([]);
  const handleD = (data: number) => {
    setPage(data);
    getWinCampaign(data, id as string, 10);
  };
  const handleBack = () => {
    navigation(`/prize-game/${id}`);
  };
  const hanldeClick = (e: any, status_gifts: boolean) => {
    setCurrent(e.target.id);
    if (status_gifts) {
      getPrizeCampaign(id as string);
    } else {
      getWinCampaign(1, id as string, 10);
    }
  };
  const getPrizeCampaign = (id: string) => {
    ListReceiptServices.ReceiptCustomerGift(id)
      .then((res: any) => {
        setDataListGift(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getWinCampaign = (page: number, id: string, limit: number) => {
    ListReceiptServices.ReceiptCustomerPrize(page, id, limit)
      .then((res: any) => {
        setTotalPrize(res.total_item);
        setDataListWin(res.list_winning);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getPrizeCampaign(id as string);
    getWinCampaign(page, id as string, 10);
    if (type === "gift") {
      setCurrent("1");
    } else {
      setCurrent("2");
    }
  }, []);
  return (
    <div>
      <HeaderFrame title="" handleBack={handleBack} iconBack={BACK} footer="">
        <div className="px-2 mt-2">
          <div className="">
            <div className="tab-receive1 flex justify-between items-center align-middle mt-5">
              <div className="flex justify-center flex-auto">
                <button
                  key="1"
                  id={"1"}
                  disabled={current === `${1}`}
                  onClick={(e) => hanldeClick(e, true)}
                  className="font-semibold-mon mt-[6px] leading-4"
                >
                  Ds quà tặng
                </button>
              </div>
              <div className="flex justify-center flex-auto">
                <button
                  key="2"
                  id={"2"}
                  disabled={current === `${2}`}
                  onClick={(e) => hanldeClick(e, false)}
                  className="font-semibold-mon mt-[6px] leading-4"
                >
                  Ds trúng thưởng
                </button>
              </div>
            </div>
            <div className=" box-border mt-5 ">
              {current === "1" ? (
                <table className="table-auto w-full text-[12px] leading-4 rounded-t-md">
                  <thead className=" h-8 rounded-t-md">
                    <tr className="bg-[#5BC2DC] text-white font-light-mon rounded-t-2xl">
                      <th className="border-head rounded-tl-xl pl-5 text-left">
                        Tên quà
                      </th>
                      <th className="border-head w-24">Tổng quà</th>
                      <th className="border-head">Đã phát</th>
                      <th className="border-head rounded-tr-xl">Còn lại</th>
                    </tr>
                  </thead>
                  <tbody className="font-bold-mon">
                    {lstGift?.map((item: ListGiftCustomer, index) => (
                      <TabGift item={item} key={index} index={index} />
                    ))}
                  </tbody>
                </table>
              ) : (
                <table className="table-auto w-full text-[12px] leading-4 rounded-t-md">
                  <thead className=" h-8 rounded-t-md">
                    <tr className="bg-[#5BC2DC] text-white font-light-mon rounded-t-2xl">
                      <th className="border-head rounded-tl-xl pl-5 text-left">
                        SĐT
                      </th>
                      <th className="border-head text-left pl-3">
                        Giải thưởng
                      </th>
                      <th className="border-head rounded-tr-xl w-40 text-right pr-1">
                        Thời gian
                      </th>
                    </tr>
                  </thead>
                  <tbody className="font-bold-mon ">
                    {listWin?.map((item: ListWinCustomer, index: number) => (
                      <TabReward item={item} key={index} index={index} />
                    ))}
                    <div className="h-20"></div>
                  </tbody>
                </table>
              )}
            </div>
            {current === "2" ? (
              <div className="flex justify-center mt-2">
                {totalPrize > 10 && (
                  <Pagination
                    defaultCurrent={page}
                    total={totalPrize}
                    onChange={handleD}
                  />
                )}

                <div className="h-40"></div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </HeaderFrame>
    </div>
  );
}
