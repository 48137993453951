import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useEffect, useState } from "react";
import "../../assets/css/Login.css";
import BACK_HEADER from "../../assets/image/Header/back-header.png";
import HeaderFrame from "../../components/HeaderFrame/HeaderFrame";
import FOOTER from "../../assets/image/Login/footer.png";
import LoadingButton from "../../components/Loading/LoadingButton";
import { useQuery } from "react-query";
import { homeServices } from "../../services/apiService/homeService";
import EYEOFF from "../../assets/image/Login/eye-off.png";
import EYEON from "../../assets/image/Login/eye-on.png";
import { DataRegister } from "../../models/ListReceiveCustomer";

export default function Register2Component({
  isLoading,
  goToNext,
  handleBack,
  dataRegister,
}: {
  dataRegister: DataRegister;
  isLoading: boolean;
  goToNext: any;
  handleBack: () => void;
}) {
  const { data } = useQuery<any>({
    queryKey: ["getCampsaign"],
    queryFn: () => homeServices.homeApi(),
  });
  const [isError, setIsError] = useState("");
  const [isEye, setIsEye] = useState(false);
  const [isEye1, setIsEye1] = useState(false);
  const onClickEye = () => {
    setIsEye(!isEye);
  };
  const onClickEye1 = () => {
    setIsEye1(!isEye1);
  };
  const onSubmit = (data: any) => {
    goToNext(data);
    console.log(data);
  };
  const {
    register,
    watch,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });
  useEffect(() => {
    if (dataRegister) {
      setValue("password", dataRegister?.password);
      setValue("confirm_password", dataRegister?.confirm_password);
    }
  }, [dataRegister]);
  return (
    <div>
      <HeaderFrame
        footer={FOOTER}
        title="TẠO MẬT KHẨU"
        iconBack={BACK_HEADER}
        handleBack={handleBack}
      >
        <div className="pb-10 px-3">
          <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-3">
              <label className="text-[#5BC2DC] font-semibold-so">
                Mật khẩu của bạn
              </label>
              <div className="rounded-lg bg-white grid grid-cols-12 px-2 mt-1 border-itemlogin">
                <input
                  type={!isEye ? "password" : ""}
                  placeholder="Vui lòng nhập mật khẩu"
                  className="w-full col-span-11 pl-3 h-12 z-0 font-light-so rounded-lg text-[12px] input-hidden"
                  {...register("password", {
                    required: "Không được để trống",
                  })}
                />
                <div
                  className="col-span-1 flex items-center"
                  onClick={onClickEye}
                >
                  {!isEye ? <img src={EYEOFF} /> : <img src={EYEON} />}
                </div>
              </div>
              <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="password"
                  render={({ messages }) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>
            </div>
            <div className="mt-3">
              <label className="text-[#5BC2DC] font-semibold-so">
                Nhập lại mật khẩu của bạn
              </label>
              <div className="rounded-lg bg-white grid grid-cols-12 px-2 mt-1 border-itemlogin">
                <input
                  type={!isEye1 ? "password" : ""}
                  placeholder="Vui lòng nhập mật khẩu"
                  className="w-full col-span-11 pl-3 h-12 z-0 font-light-so rounded-lg text-[12px] input-hidden"
                  {...register("confirm_password", {
                    required: "Không được để trống",
                    validate: (val) => {
                      if (watch("password") != val) {
                        return "Mật khẩu không khớp";
                      }
                    },
                  })}
                />
                <div
                  className="col-span-1 flex items-center"
                  onClick={onClickEye1}
                >
                  {!isEye1 ? <img src={EYEOFF} /> : <img src={EYEON} />}
                </div>
              </div>
              <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="confirm_password"
                  render={({ messages }) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>
            </div>
            {isError ? (
              <div className="font-normal z-0 mt-3 text-[red] text-[13px] text-center">
                {isError}
              </div>
            ) : null}
            <div className="mt-10">
              <div className="mt-3 flex justify-center">
                {isLoading ? (
                  <div className="bg-[#5BC2DC] text-center w-40 px-8 font-semibold-so py-[12px] text-[14px] rounded-lg text-white">
                    <LoadingButton />
                  </div>
                ) : (
                  <button className="bg-[#5BC2DC] w-40 px-8 font-semibold-so py-3 text-[14px] rounded-lg text-white">
                    Tiếp theo
                  </button>
                )}
              </div>
            </div>
          </form>
          <div className="my-3 font-light-so text-[#424242] text-[12px] text-center px-2">
            Gọi điện cho Hotline
            <span className="text-[#4E9ABB] text-[14px] font-medium-so">
              {" "}
              <a href={`tel:${data?.hotline}`}>{data?.hotline} </a>
            </span>
            để được hỗ trợ
          </div>
        </div>
      </HeaderFrame>
    </div>
  );
}
