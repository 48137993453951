import TAMGAT from "../../../assets/image/Game/Gacha/tam-gat.png";
import GAT from "../../../assets/image/Game/Gacha/gat.png";
import "../../../assets/css/Gacha.css";
import { useEffect, useState } from "react";
import BackgroundGame from "../../../components/BackgroundPopup/BackgroundGame";
import PopupOverlay from "../../../components/BackgroundPopup/PopupOverLay";
import {
  LUOT_QUAY,
  userLocal,
} from "../../../services/localService/localService";
import { GameServices } from "../../../services/apiService/GameService";
import { useNavigate, useParams } from "react-router-dom";
import { setAuthorization } from "../../../services/apiService/configURL";
import Loading from "../../../components/Loading/Loading";
import { generateRandomDuplicateList } from "../../../utils/formatData";
import PopupError from "../../../components/Popup/PopupError";
export default function Gacha() {
  let { token } = userLocal.get();
  const { campaign_id, sale_id } = useParams();
  const [lat, setLat] = useState<Boolean>(false);
  const [play, setPlay] = useState<Boolean>(false);
  const [playGap, setPlayGap] = useState<Boolean>(false);
  const [isLoading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [popUpGift, setPopupGift] = useState("no");
  const [messError, setMessError] = useState("");
  const [spinRemain, setSpinRemain] = useState<number>(0);
  const [lineId, setLineId] = useState();
  const [gift, setGift] = useState<any>();
  const [game, setGame] = useState<any>();
  const [listDraw, setListDraw] = useState<any>();
  const [isOpenPopup, setIsPopup] = useState<boolean>(false);
  const navigation = useNavigate();
  setAuthorization(token);
  useEffect(() => {
    console.log(token);

    setLoading(true);
    setAuthorization(token);
    getCalculator(sale_id, campaign_id);
  }, []);
  useEffect(() => {
    getCalculator(sale_id, campaign_id);
    setAuthorization(token);
  }, [popUpGift === "change"]);
  const getCalculator = (
    sale_id: string | undefined,
    campaign_id: string | undefined
  ) => {
    setAuthorization(token);
    const params = {
      sale_id: sale_id,
      campaign_id: campaign_id,
    };
    GameServices.CalculatorWheelApi(params)
      .then((res: any) => {
        if (res.remaining_draw === 0) {
          setIsError(true);
          setMessError("Bạn đã hết lượt chơi !!!");
        } else {
          setLoading(false);
          setGame(res.lucky_draw_list.game_data);
          setPopupGift("no");
          console.log(res);
          setSpinRemain(res.remaining_draw);
          const countGiftWin = res.total_draw - res.remaining_draw;
          console.log(countGiftWin);
          const giftWin = res.lucky_draw_list.list_lucky_draw[0].list_win[0];
          const listDraw = res.lucky_draw_list.list_lucky_draw[0].list_draw;
          console.log(listDraw);
          const newDraw = generateRandomDuplicateList(listDraw, 10);
          console.log(newDraw);
          setListDraw(newDraw);
          setGift(giftWin);
          setLineId(giftWin?.line_id);
          localStorage.setItem(
            "LIST_LUCKY_DRAW",
            JSON.stringify(res.lucky_draw_list.list_lucky_draw)
          );
          localStorage.setItem("GIFT_WHEEL", JSON.stringify(giftWin));
          localStorage.setItem(LUOT_QUAY, res.remaining_draw);
        }
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
        console.log(err);
      });
  };
  const [lock, setLock] = useState<boolean>(false);
  const handlePlay = () => {
    console.log("ấn vào quay");
    if (!lock) {
      setLock(true);
      setLat(true);

      setTimeout(() => {
        setPlay(true);
        const data = {
          sale_id: sale_id,
          campaign_id: campaign_id,
          line_id: lineId,
        };
        console.log(data);
        GameServices.CountDrawApi(data)
          .then((res: any) => {
            console.log("trả data:", res);
            console.log(spinRemain);
            localStorage.setItem(LUOT_QUAY, res.remaining_draw);
            setSpinRemain(res.remaining_draw);
            setTimeout(() => {
              setPlay(false);
              setTimeout(() => {
                console.log("gap");
                setPlayGap(true);
                setTimeout(() => {
                  // setTimeout(() => {
                  setIsPopup(true);
                  // }, 10);
                }, 900);
              }, 100);
            }, 5000);
          })
          .catch((err) => {
            setIsError(true);
            setMessError(err);

            console.error(err);
          });
      }, 500);
    }
  };
  const handleClosePopup = () => {
    const luot_quay = localStorage.getItem(LUOT_QUAY);
    if (luot_quay === "0") {
      navigation("/list-gift");
    } else {
      setLock(false);
      setPopupGift("change");
      setIsPopup(false);
      setPlayGap(false);
      setPlay(false);
      setLat(false);
    }
  };
  const handleCloseError = () => {
    navigation("/games");
  };
  return (
    <div>
      {isLoading ? <Loading /> : null}
      {game && (
        <BackgroundGame img={game?.background_image_url}>
          <div className="absolute top-12">
            <img src={game?.title_image_url} loading="lazy" />
          </div>
          <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-10/12">
            <div className="w-full relative z-30">
              <img
                src={game?.machine_header_image_url}
                loading="lazy"
                className="w-full"
              />
            </div>
            <div className="w-full relative top-0 -mt-[1px] z-20">
              <img
                src={game?.machine_body_image_url}
                loading="lazy"
                className="w-full"
              />
              <div className="absolute bottom-4 px-12 w-full">
                <div className="grid grid-cols-2 gap-2 relative -bottom-24">
                  <div
                    className={
                      play ? "flex justify-end fly1" : "flex justify-end"
                    }
                  >
                    <div className="relative rotate1">
                      <img
                        src={game?.machine_gift_box_image_url}
                        loading="lazy"
                      />
                      <div className="absolute top-2 left-4">
                        <img
                          src={listDraw[0]?.gift_square_image_url}
                          className="w-10 img-gift"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      play ? "flex justify-start fly2" : "flex justify-start"
                    }
                  >
                    <div className="relative rotate2">
                      <img
                        src={game?.machine_gift_box_image_url}
                        loading="lazy"
                      />
                      <div className="absolute top-2 left-4">
                        <img
                          src={listDraw[1]?.gift_square_image_url}
                          className="w-10 img-gift"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-2 relative -bottom-[75px] px-2">
                  <div
                    className={
                      play ? "flex justify-end fly3" : "flex justify-end"
                    }
                  >
                    <div className="relative rotate3">
                      <img
                        src={game?.machine_gift_box_image_url}
                        loading="lazy"
                      />
                      <div className="absolute top-2 left-4">
                        <img
                          src={listDraw[2]?.gift_square_image_url}
                          className="w-10 img-gift"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      play ? "flex justify-end fly4" : "flex justify-end"
                    }
                  >
                    <div className="relative rotate4">
                      <img
                        src={game?.machine_gift_box_image_url}
                        loading="lazy"
                      />
                      <div className="absolute top-2 left-4">
                        <img
                          src={listDraw[3]?.gift_square_image_url}
                          className="w-10 img-gift"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      play ? "flex justify-end fly5" : "flex justify-end"
                    }
                  >
                    <div className="relative rotate1">
                      <img
                        src={game?.machine_gift_box_image_url}
                        loading="lazy"
                      />
                      <div className="absolute top-2 left-4">
                        <img
                          src={listDraw[4]?.gift_square_image_url}
                          className="w-10 img-gift"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-2 relative -bottom-10">
                  <div
                    className={
                      play ? "flex justify-end fly6" : "flex justify-end"
                    }
                  >
                    <div className="relative rotate2">
                      <img
                        src={game?.machine_gift_box_image_url}
                        loading="lazy"
                      />
                      <div className="absolute top-2 left-4">
                        <img
                          src={listDraw[5]?.gift_square_image_url}
                          className="w-10 img-gift"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      play ? "flex justify-start fly7" : "flex justify-start"
                    }
                  >
                    <div className="relative rotate3">
                      <img
                        src={game?.machine_gift_box_image_url}
                        loading="lazy"
                      />
                      <div className="absolute top-2 left-4">
                        <img
                          src={listDraw[6]?.gift_square_image_url}
                          className="w-10 img-gift"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-2 relative bottom-0">
                  <div
                    className={
                      play ? "flex justify-end fly8" : "flex justify-end"
                    }
                  >
                    <div className="relative rotate4">
                      <img
                        src={game?.machine_gift_box_image_url}
                        loading="lazy"
                      />
                      <div className="absolute top-2 left-4">
                        <img
                          src={listDraw[7]?.gift_square_image_url}
                          className="w-10 img-gift"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      play ? "flex justify-end fly9" : "flex justify-end"
                    }
                  >
                    <div className="relative rotate1">
                      <img
                        src={game?.machine_gift_box_image_url}
                        loading="lazy"
                      />
                      <div className="absolute top-2 left-4">
                        <img
                          src={listDraw[8]?.gift_square_image_url}
                          className="w-10 img-gift"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      play ? "flex justify-end fly10" : "flex justify-end"
                    }
                  >
                    <div className="relative rotate2">
                      <img
                        src={game?.machine_gift_box_image_url}
                        loading="lazy"
                      />
                      <div className="absolute top-2 left-4">
                        <img
                          src={listDraw[9]?.gift_square_image_url}
                          className="w-10 img-gift"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full -mt-[1px] relative top-0 z-40">
              <img
                src={game?.machine_table_image_url}
                loading="lazy"
                className="w-full relative z-40"
              />
            </div>
            <div className={"w-full -mt-[1px] relative top-0 z-10"}>
              <img
                src={game?.machine_footer_image_url}
                loading="lazy"
                className="w-full"
              />
              <div className="absolute top-1 right-[15%]">
                <img src={TAMGAT} loading="lazy" />
                <div
                  className="absolute w-full top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
                  onClick={handlePlay}
                >
                  <img
                    src={GAT}
                    loading="lazy"
                    className={
                      lat ? "w-full rotate-gat" : "w-full rotate-gat-finish"
                    }
                  />
                </div>
              </div>
              {playGap ? (
                <div className="absolute top-3 left-[22%] fly-prize z-0">
                  <div className="relative z-0">
                    <img
                      src={game?.machine_gift_box_image_url}
                      loading="lazy"
                      className="relative z-0"
                    />
                    <div className="absolute top-2 left-4">
                      <img
                        src={gift?.gift_square_image_url}
                        className="w-10 img-gift"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="absolute -bottom-16 w-full flex justify-center">
                <button
                  className="px-10 py-3 rounded-3xl"
                  style={{
                    backgroundColor: game?.button_color,
                    border: `2px solid ${game?.button_border_color}`,
                    color: game?.button_text_color,
                  }}
                >
                  {spinRemain > 0
                    ? `Bạn có ${spinRemain} lượt`
                    : "Bạn hết lượt chơi"}
                </button>
              </div>
            </div>
          </div>
          <PopupOverlay isShow={isOpenPopup}>
            <div className="relative text-white left-1/2 -translate-x-1/2 w-full animation-popupgacha">
              <div className="relative text-white left-1/2 -translate-x-1/2 w-full px-5">
                <img src={game?.gift_frame_url} />
                <div className="absolute top-[15%] w-full leading-7 left-1/2 -translate-x-1/2 text-[28px] font-bold-so uppercase text-[#ED3136]">
                  <div className="text-shadow-title">Chúc mừng bạn</div>
                  <div className="lowercase text-[20px] text-white">
                    đã trúng
                  </div>
                  <div className="lowercase text-[20px] text-white font-semibold-so">
                    {gift?.gift_name}
                  </div>
                </div>
                <div className="absolute top-[70%] -translate-y-3/4 left-1/2 -translate-x-1/2 z-0">
                  <div className="relative z-0">
                    <img
                      src={gift?.gift_reward_image_url}
                      loading="lazy"
                      className="relative z-0 w-80"
                    />
                  </div>
                </div>
                <div className="absolute bottom-5 w-11/12 flex justify-center">
                  <button
                    className="px-10 py-3 rounded-[100px]"
                    style={{
                      backgroundColor: game?.button_color,
                      border: `2px solid ${game?.button_border_color}`,
                      color: game?.button_text_color,
                    }}
                    onClick={handleClosePopup}
                  >
                    Nhận ngay
                  </button>
                </div>
              </div>
            </div>
          </PopupOverlay>
        </BackgroundGame>
      )}
      <PopupError
        labelBtn="Đồng ý"
        messError={messError}
        handleClose={handleCloseError}
        isShow={isError}
      />
    </div>
  );
}
