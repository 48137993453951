import { useNavigate } from "react-router-dom";
import BACK from "../../assets/image/Header/back-header.png";
import { useEffect, useState } from "react";
import { GameServices } from "../../services/apiService/GameService";
import { ListLuckyDraw } from "../../models/ListRotation";
import "../../assets/css/Listrotation.css";
import ItemRotation from "./ItemRotation";
import { userLocal } from "../../services/localService/localService";
import { setAuthorization } from "../../services/apiService/configURL";
import HeaderFrame from "../../components/HeaderFrame/HeaderFrame";
import { Pagination } from "antd";
import SkelatonRotation from "../../components/Skelaton/SkelatonRotation";

export default function ListRotation() {
  const navigation = useNavigate();
  const [listRotation, setListRotation] = useState<[ListLuckyDraw]>();
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleBack = () => {
    document.body.style.overflow = "unset";
    navigation("/");
  };
  let { token } = userLocal.get();
  setAuthorization(token);

  useEffect(() => {
    setIsLoading(true);
    getWinCampaign("", page, limit);
    localStorage.removeItem("GAME_DATA_LOCAL");
    localStorage.removeItem("LUOT_QUAY");
    localStorage.removeItem("SALE_ID");
  }, []);
  const getWinCampaign = (status: string, page: number, limit: number) => {
    GameServices.ListRotation("", page, limit)
      .then((res: any) => {
        console.log(res);
        setListRotation(res.lucky_draw_list);
        setTotalPage(res?.total_item);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleD = (data: number) => {
    console.log(data);
    setPage(data);
    setIsLoading(true);
    getWinCampaign("", data, limit);
  };
  return (
    <div>
      <HeaderFrame
        footer=""
        title="Lượt chơi của bạn"
        handleBack={handleBack}
        iconBack={BACK}
      >
        <div className=" mb-5">
          {listRotation ? (
            <div>
              {(listRotation?.length as number) !== 0 ? (
                listRotation?.map((res: ListLuckyDraw, index: number) => (
                  <div key={index}>
                    <ItemRotation res={res} index={index} />
                  </div>
                ))
              ) : (
                <div className="mt-20 text-center">
                  Tiếc quá, bạn chưa có lượt chơi nào. Tham gia ngay các chương
                  trình khuyến mãi để nhận thêm nhiều phần quà hấp dẫn nhé!
                </div>
              )}
            </div>
          ) : (
            <div>
              <SkelatonRotation />
              <SkelatonRotation />
              <SkelatonRotation />
              <SkelatonRotation />
              <SkelatonRotation />
              <SkelatonRotation />
            </div>
          )}

          <div className="flex justify-center mt-10">
            {totalPage > 10 && (
              <Pagination
                defaultCurrent={page}
                total={totalPage}
                onChange={handleD}
              />
            )}
          </div>
        </div>
      </HeaderFrame>
    </div>
  );
}
