import { axiosInstance } from "./configURL";

export const LeaderBoardServices = {
  LeaderBoard: (campaign_id: string, race_type: string, race_id: string) => {
    return axiosInstance.get(
      `/campaign/leaderboard?campaign_id=${campaign_id}&race_type=${race_type}&race_id=${race_id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  LeaderBoardResult: (
    campaign_id: string,
    race_type: string,
    race_id: string
  ) => {
    return axiosInstance.get(
      `/campaign/leaderboard/result?campaign_id=${campaign_id}&race_type=${race_type}&race_id=${race_id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  Race: (campaign_id: string, race_type: string) => {
    return axiosInstance.get(
      `/campaign/leaderboard/race?campaign_id=${campaign_id}&race_type=${race_type}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
};
