import { Fragment } from "react/jsx-runtime";

export default function Tree({ tree }: { tree: string }) {
  return (
    <Fragment>
      <div className="absolute bottom-5 w-full z-0 ">
        <img src={tree} className="relative left-1/2 -translate-x-1/2 z-0" />
      </div>
      <div className="relative top-0 w-full left-1/2 -translate-x-1/2 land-tree -z-10"></div>
    </Fragment>
  );
}
