import { useEffect, useState } from "react";
import { userLocal } from "../../services/localService/localService";
import { setAuthorization } from "../../services/apiService/configURL";
import { homeServices } from "../../services/apiService/homeService";
import Register1Component from "./Register1Component";
import { ProfileModel } from "../../models/LoginModel";
import PopupError from "../../components/Popup/PopupError";
import { useNavigate } from "react-router-dom";

const title = "Cập nhật thông tin";

export default function UpdateProfile() {
  const navigation = useNavigate();
  const [profile, setProfile] = useState<any>();
  let { token } = userLocal.get();
  const [update, setUpdate] = useState("");
  const [status, setStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  setAuthorization(token);
  useEffect(() => {
    homeServices
      .GetProfile()
      .then((res: any) => {
        setProfile(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const goToPrevious = (e: ProfileModel) => {
    setIsLoading(true);
    e["phone"] = profile.phone;
    homeServices
      .UpdateProfile(e)
      .then((res) => {
        setUpdate("Cập nhật thông tin cá nhân thành công.");
        setStatus(true);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClose = () => {
    navigation("/profile");
  };
  const handleNext = (e: any) => {};

  return (
    <div>
      <div>
        <Register1Component
          handleNext={(e: any) => handleNext(e)}
          required={true}
          dataRegister={profile}
          goToPrevious={(e: any) => goToPrevious(e)}
          title={title}
          consent={false}
          labelButton="Lưu thông tin"
          updateAfter={false}
          handleBack={handleClose}
          isLoading={isLoading}
        />
      </div>
      <PopupError
        isShow={status}
        labelBtn="Đồng ý"
        handleClose={handleClose}
        messError={update}
      />
    </div>
  );
}
