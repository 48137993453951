import PHONE from "../../assets/image/Home/icon-phone.png";
import ZALO from "../../assets/image/Home/iconzalo.png";

export default function ContactPhoneZalo() {
  const { hotline, zalo_link } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  return (
    <div className="fixed bottom-32 z-40 right-2 flex flex-col justify-center items-center">
      <a href={`tel:${hotline}`}>
        <img src={PHONE} className="w-14" />
      </a>
      <a href={`${zalo_link}`} target="_blank" className="mt-3">
        <img src={ZALO} className="w-12" />
      </a>
    </div>
  );
}
