export default function TabNewsEvent({
  current,
  handleClick,
}: {
  current: string;
  handleClick: (e: any, status: string) => void;
}) {
  return (
    <div>
      <div>
        <div className="tab-home flex justify-between items-center align-middle">
          <div className="flex flex-col items-center justify-center flex-auto">
            <button
              key="1"
              id={"1"}
              disabled={current === "1"}
              onClick={(e) => handleClick(e, "quotation")}
              className="font-medium-mon leading-4 mt-[8px] text-[11px]"
            >
              Trang chủ
            </button>
            {current === "1" ? (
              <div className="w-20 h-[2px] border-tab mt-[2px]"></div>
            ) : null}
          </div>
          <div className="flex flex-col items-center justify-center flex-auto">
            <button
              key="2"
              id={"2"}
              disabled={current === "2"}
              onClick={(e) => handleClick(e, "done")}
              className="font-medium-mon leading-4 mt-[8px] text-[12px]"
            >
              Thương hiệu
            </button>
            {current === "2" ? (
              <div className="w-20 h-[2px] border-tab mt-[2px]"></div>
            ) : null}
          </div>
          <div className="flex flex-col items-center justify-center flex-auto">
            <button
              key="3"
              id={"3"}
              disabled={current === "3"}
              onClick={(e) => handleClick(e, "wait")}
              className="font-medium-mon leading-4 mt-[8px] text-[12px]"
            >
              Sự kiện
            </button>
            {current === "3" ? (
              <div className="w-20 h-[2px] border-tab mt-[2px]"></div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
