import HeaderBack from "../../components/HeaderBack/HeaderBack";
import BG_RANK from "../../assets/image/AccumulatePoint/bg-ranks.png";
import { useState } from "react";
import ChooseHome from "../../components/NavChoosePageHome/ChooseHome";
import ICON_GIFT from "../../assets/image/Home/icon-gift.png";
import { locationBefore } from "../../services/localService/localService";
import BACK_HEADER from "../../assets/image/Header/back-header.png";
import LoyaltyCards from "../../components/LoyaltyCards/LoyaltyCards";

export default function AccumulatePoints() {
  const [sliderValue, setSliderValue] = useState(1600);
  const [totalCoin, setTotalCoin] = useState(2000);
  const location = locationBefore.get();

  function handleChange(e: any) {
    setSliderValue(e.value);
  }
  return (
    <div>
      <div className="px-5">
        <HeaderBack
          name="Tích điểm"
          navigate={location}
          imageBack={BACK_HEADER}
        />
        <div>
          <div className="relative top-0">
            <LoyaltyCards
              imageBackground={BG_RANK}
              coin={sliderValue}
              totalCoin={totalCoin}
              handleChange={(e: any) => handleChange(e)}
            />
          </div>
          <div className="my-3">
            <ChooseHome
              value="20+"
              icon={ICON_GIFT}
              name="Ưu đãi thành viên"
              navigate="/brand"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
