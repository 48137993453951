import { useNavigate } from "react-router-dom";
import "../../../../assets/css/PlantTree.css";
import TREE from "../../../../assets/image/Game/PlantTree/big-tree.png";
import BUTTONJOIN from "../../../../assets/image/Game/PlantTree/button-join.png";
import TITLE_PLANT from "../../../../assets/image/Game/PlantTree/title-plant.png";

export default function BackgroundGame({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="bg-joingame relative top-0">
      <div className="absolute top-[3%] w-full flex justify-center">
        <img src={TITLE_PLANT} className="w-9/12" />
      </div>
      {children}
    </div>
  );
}
