import { AxiosResponse } from "axios";
import { axiosInstance } from "./configURL";
import { ProfileModel } from "../../models/LoginModel";
interface CampaignData {
  points: number; // adjust this based on your actual data
  // other properties
}
export const homeServices = {
  homeApi: (): Promise<AxiosResponse<CampaignData>> => {
    return axiosInstance.get<CampaignData>("/campaign/home");
  },
  ocrEndPointApi: (data: any) => {
    return axiosInstance.get(`/campaign/ocr_method?campaign_id=${data}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  submitReceiptApi: (data: any) => {
    return axiosInstance.post(
      "/campaign/submit_receipt",
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  RecallSubmitReceiptApi: (sale_id: string, lastCall: boolean) => {
    return axiosInstance.post(
      "/campaign/submit_receipt/recall",
      { params: { sale_id: sale_id, last_call: lastCall } },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  PushReviewReceiptApi: (sale_id: string) => {
    return axiosInstance.post(
      "/campaign/submit_receipt/push_approve",
      { params: { sale_id: sale_id } },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  customerConfirmApi: (data: any) => {
    return axiosInstance.post(
      "/campaign/consumer_confirm",
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  GetNews: () => {
    return axiosInstance.get(`/campaign/news`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  GetNewsDetails: (id: string) => {
    return axiosInstance.get(`/campaign/news/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  GetProfile: () => {
    return axiosInstance.get(`/consumer/profile`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  UpdateProfile: (data: ProfileModel) => {
    return axiosInstance.post(
      `/consumer/profile/update`,
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
};
