import OverlayPopup from "../../OverlayPopup/OverlayPopup";
import TITLE from "../../../assets/image/Game/LuckyMachine/title-ratings.png";
import DOWN from "../../../assets/image/LeaderBoard/down.png";
import CALENDAR from "../../../assets/image/Prize/calendar-select.png";
import ItemRating from "./ItemRating";
export default function PopupRatings() {
  return (
    <OverlayPopup isShow={true}>
      <div className="relative top-[8vh]">
        <div className="absolute -top-[100px] z-30 w-full left-0 flex justify-center">
          <img src={TITLE} />
        </div>
        <div className="bg-main rounded-t-[55px] rounded-b-[50px] p-2 z-20 overflow-hidden">
          <div className="bg-contain relative rounded-t-[50px] rounded-b-[45px] z-20 overflow-hidden">
            <div className="fixed z-10 w-10/12 h-[75px] left-1/2 -translate-x-1/2 bg-white rounded-t-[40px]"></div>
            <div className="fixed z-10 w-6/12 left-1/2 -translate-x-1/2 bg-[#7dcfeb] rounded-lg flex justify-center top-[100px] text-white">
              <div className="absolute top-1/2 -translate-y-1/2 left-2">
                <img src={CALENDAR} />
              </div>
              <select
                className="text-white rounded-lg bg-[#7dcfeb] px-10 py-2 input-hidden"
                // onChange={(e) => handleWeek(e)}
                // defaultValue={race_id_select}
                // value={race_id_select}
              >
                {/* {listRace?.length !== 0 && (
              <>
                {listRace?.map((res: dataExample) => (
                  <option key={res.race_id} value={res.race_id}>
                    {res.week}
                  </option>
                ))}
              </>
            )} */}
                <option>hello</option>
                <option>hello</option>
              </select>
              <div className="absolute top-1/2 -translate-y-1/2 right-2">
                <img src={DOWN} className="w-3" />
              </div>
            </div>
            <div className="relative top-[90px] px-4">
              <ItemRating />
            </div>
          </div>
        </div>
      </div>
    </OverlayPopup>
  );
}
