import BACKGROUND from "../../../assets/image/Game/PeerJumpTower/background.png";
import TITLE from "../../../assets/image/Game/PeerJumpTower/title.png";
import FLY from "../../../assets/image/Game/PeerJumpTower/fly.png";
import LINE from "../../../assets/image/Game/PeerJumpTower/line.png";
import TEAPLUS from "../../../assets/image/Game/PeerJumpTower/teaplus.png";
import BG_POINT from "../../../assets/image/Game/PeerJumpTower/bg-point.png";
import CLICK from "../../../assets/image/Game/PeerJumpTower/click.png";
import videos from "../../../assets/image/up.mp4";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OverlayPopup from "../../../components/OverlayPopup/OverlayPopup";
import { GameServices } from "../../../services/apiService/GameService";
import { GameDetailsModel } from "../../../models/GameModel";
import { LUOT_QUAY } from "../../../services/localService/localService";
import Loading from "../../../components/Loading/Loading";
import PopupError from "../../../components/Popup/PopupError";

export default function PeerJumpTower() {
  const [game, setBackgroundGame] = useState<GameDetailsModel>();
  const [dataCallUrbox, setDataCallUrbox] = useState<number>(0);
  const [gift, setGift] = useState<any>();
  const [win, setwin] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [stt, setStt] = useState("0");
  const [sttFly, setSttFly] = useState("0");
  const [disable, setDisable] = useState(false);
  const [video, setVideo] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [messError, setMessError] = useState<string>("Error");
  const [segments, setsegments] = useState<any>();
  const [lineId, setLineId] = useState();
  const [popUpGift, setPopupGift] = useState("no");

  const location_status_game = localStorage.getItem("LOCATION_STATUS_GAME");
  const [isPopupGuide, setIsPopupGuide] = useState(
    location_status_game === "TRUE" ? true : false
  );
  const { campaign_id, sale_id } = useParams<string>();

  useEffect(() => {
    localStorage.removeItem("CONFIRM");
    getCalculator(sale_id, campaign_id);
  }, []);
  const navigation = useNavigate();
  const handleChoose = (e: string) => {
    setDisable(true);
    if (!disable) {
      document.body.style.overflow = "hidden";
      console.log(e);
      setStt(e);
      countDraw();
      setTimeout(() => {
        setSttFly(e);
        setTimeout(() => {
          // setVideo(true);
          // setTimeout(() => {
          //   navigation(`/peerreceive/${campaign_id}/${sale_id}`);
          // }, 3800);
          navigation(`/peerreceive/${campaign_id}/${sale_id}`);
        }, 1100);
      }, 1800);
    }
  };
  const getCalculator = (
    sale_id: string | undefined,
    campaign_id: string | undefined
  ) => {
    setIsLoading(true);
    const params = {
      sale_id: sale_id,
      campaign_id: campaign_id,
    };
    GameServices.CalculatorWheelApi(params)
      .then((res: any) => {
        console.log(res);
        if (res.remaining_draw === 0) {
          setIsError(true);
          setMessError("Bạn đã hết lượt chơi !!!");
        } else {
          setPopupGift("no");
          setDataCallUrbox(res.remaining_draw);
          setsegments(res.lucky_draw_list.list_lucky_draw[0].list_draw);
          setBackgroundGame(res.lucky_draw_list.game_data);
          let itemWin = res.lucky_draw_list.list_lucky_draw[0].list_win[0];
          setLineId(itemWin?.line_id);
          console.log(itemWin);
          let codeWin = itemWin.gift_code;
          console.log(itemWin);
          let segmentsTemp = res.lucky_draw_list.list_lucky_draw[0].list_draw;
          console.log(segmentsTemp);
          localStorage.setItem("GIFT_WHEEL", JSON.stringify(itemWin));
          localStorage.setItem(
            "GAME_DATA_LOCAL",
            JSON.stringify(res.lucky_draw_list.game_data)
          );
          localStorage.setItem(LUOT_QUAY, res.remaining_draw);
          setGift(itemWin);
          const a = segmentsTemp.findIndex((x: any) => x.gift_code === codeWin);
          console.log(a);
          setwin(a + 1);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
        console.log(err);
      });
  };
  const countDraw = async () => {
    const data = {
      sale_id: sale_id,
      campaign_id: campaign_id,
      line_id: lineId,
    };
    console.log(data);

    GameServices.CountDrawApi(data)
      .then((res: any) => {
        console.log(res);
        localStorage.setItem(LUOT_QUAY, res.remaining_draw);
        localStorage.setItem("CONFIRM", "CONFIRM");
        if (res.remaining_draw === 0) {
          setTimeout(() => {
            const dataParams = {
              sale_id: sale_id,
              campaign_id: campaign_id,
            };
            GameServices.SendGiftApi(dataParams)
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });
          }, 5000);
        }

        localStorage.setItem("LUCKY_CODE", JSON.stringify(res));
        setDataCallUrbox(res.remaining_draw);
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
        console.log(err);
      });
  };
  const handleClearPopup = () => {
    localStorage.removeItem("LOCATION_STATUS_GAME");
    setIsPopupGuide(false);
  };
  const handleCloseError = () => {
    navigation("/games");
    setIsError(false);
  };
  return (
    <div className="relative top-0">
      {isLoading && <Loading />}
      {!isError && (
        <OverlayPopup isShow={isPopupGuide}>
          <div onClick={handleClearPopup}>
            <div className="text-white font-light-so">
              Nhấp vào chai bất kỳ
              <br /> trên màn hình điện thoại
            </div>
            <div className="relative top-5">
              <div className={`flex flex-col items-center relative`}>
                <div className={`flex flex-col items-center`}>
                  <div className="relative">
                    <img src={FLY} className="w-14" />
                  </div>
                  <div className="relative -top-5">
                    <img src={LINE} />
                  </div>
                </div>
                <div className="w-5/12 relative flex justify-center -top-24 rotate-gap-fly1">
                  <img src={TEAPLUS} />
                </div>
              </div>
            </div>
            <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
              <img src={CLICK} className="click-animation" />
            </div>
          </div>
        </OverlayPopup>
      )}

      {video ? (
        <div className="stylevideo">
          <video
            width="100%"
            playsInline
            preload="metadata"
            autoPlay
            disablePictureInPicture
            controls={false}
            style={{ opacity: 1 }}
          >
            <source src={videos} type="video/mp4" />
          </video>
        </div>
      ) : (
        <div className="relative top-0">
          <div className="relative top-0">
            <img src={BACKGROUND} />
          </div>
          <div className="absolute top-0 w-full">
            <div className="flex justify-center w-full relative z-10">
              <img src={TITLE} className="w-9/12" />
              <div className="fixed w-full flex justify-center bottom-0">
                <div className="relative top-0 w-full flex justify-center">
                  <img src={BG_POINT} />
                </div>
                <div className="absolute top-[20%] left-1/2 -translate-x-1/2 font-light-so">
                  {dataCallUrbox > 0 ? (
                    <>
                      Bạn có{" "}
                      <span className="text-[18px] text-[#3A9244] font-semibold-so">
                        {dataCallUrbox}
                      </span>{" "}
                      lượt chơi{" "}
                    </>
                  ) : (
                    <div className="">Hết lượt chơi</div>
                  )}
                </div>
              </div>
            </div>

            <div className="px-10 -mt-5">
              <div className="grid grid-cols-3 gap-3">
                <div
                  className={`${
                    stt === "1" ? "choose-flycam z-20" : "z-0"
                  } flex flex-col items-center justify-center relative
             transfer-flycam `}
                  onClick={() => handleChoose("1")}
                >
                  <div
                    className={`${
                      sttFly === "1" ? "coke-bottom" : ""
                    } flex flex-col items-center justify-center`}
                  >
                    <div className="relative">
                      <img src={FLY} className="w-14" />
                    </div>
                    <div className="relative -top-5">
                      <img src={LINE} />
                    </div>
                  </div>
                  <div className="w-5/12 relative -top-24 rotate-gap-fly1">
                    <img src={TEAPLUS} />
                  </div>
                </div>
                <div
                  className={`${
                    stt === "2" ? "choose-flycam2 z-20" : "z-0"
                  } flex flex-col items-center justify-end relative top-3`}
                  onClick={() => handleChoose("2")}
                >
                  <div
                    className={`${
                      sttFly === "2" ? "coke-bottom" : ""
                    } flex flex-col items-center justify-center`}
                  >
                    <div className="relative">
                      <img src={FLY} className="w-8" />
                    </div>
                    <div className="relative -top-3">
                      <img src={LINE} />
                    </div>
                  </div>
                  <div className="w-4/12 relative -top-24 rotate-gap-fly2">
                    <img src={TEAPLUS} />
                  </div>
                </div>
                <div
                  className={`${
                    stt === "3" ? "choose-flycam3 z-20" : "z-0"
                  } flex flex-col items-center justify-center relative -top-3`}
                  onClick={() => handleChoose("3")}
                >
                  <div
                    className={`${
                      sttFly === "3" ? "coke-bottom" : ""
                    } flex flex-col items-center justify-center`}
                  >
                    <div className="relative">
                      <img src={FLY} className="w-14" />
                    </div>
                    <div className="relative -top-5">
                      <img src={LINE} />
                    </div>
                  </div>
                  <div className="w-5/12 relative -top-24 rotate-gap-fly3">
                    <img src={TEAPLUS} />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-3">
                <div
                  className={`${
                    stt === "4" ? "choose-flycam4 z-20" : "z-0"
                  } flex flex-col items-center justify-center relative`}
                  onClick={() => handleChoose("4")}
                >
                  <div
                    className={`${
                      sttFly === "4" ? "coke-bottom" : ""
                    } flex flex-col items-center justify-center`}
                  >
                    <div className="relative">
                      <img src={FLY} className="w-11" />
                    </div>
                    <div className="relative -top-3">
                      <img src={LINE} />
                    </div>
                  </div>
                  <div className="w-4/12 relative -top-24 rotate-gap-fly4">
                    <img src={TEAPLUS} />
                  </div>
                </div>
                <div
                  className={`${
                    stt === "5" ? "choose-flycam5 z-20" : "z-0"
                  } flex flex-col items-center justify-end relative -top-5`}
                  onClick={() => handleChoose("5")}
                >
                  <div
                    className={`${
                      sttFly === "5" ? "coke-bottom" : ""
                    } flex flex-col items-center justify-center`}
                  >
                    <div className="relative">
                      <img src={FLY} className="w-14" />
                    </div>
                    <div className="relative -top-1">
                      <img src={LINE} />
                    </div>
                  </div>
                  <div className="w-7/12 relative -top-24 rotate-gap-fly5">
                    <img src={TEAPLUS} />
                  </div>
                </div>
                <div
                  className={`${
                    stt === "6" ? "choose-flycam6 z-20" : "z-0"
                  } flex flex-col items-center justify-center relative -top-3`}
                  onClick={() => handleChoose("6")}
                >
                  <div
                    className={`${
                      sttFly === "6" ? "coke-bottom" : ""
                    } flex flex-col items-center justify-center`}
                  >
                    <div className="relative">
                      <img src={FLY} className="w-6" />
                    </div>
                    <div className="relative -top-1">
                      <img src={LINE} />
                    </div>
                  </div>
                  <div className="w-4/12 relative -top-24 rotate-gap-fly6">
                    <img src={TEAPLUS} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <PopupError
        labelBtn={"Đồng ý"}
        messError={messError}
        handleClose={handleCloseError}
        isShow={isError}
      />
    </div>
  );
}
