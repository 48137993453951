import { Fragment } from "react/jsx-runtime";
import { ListBrands } from "../../models/HomePage";
import ItemBrand from "./ItemBrand";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { homeBanner } from "../../redux/slices/HomeSlice";
import { useNavigate } from "react-router-dom";
import BACK from "../../assets/image/Header/back-header.png";
import HeaderFrame from "../../components/HeaderFrame/HeaderFrame";

export default function ListBrand() {
  const navigation = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    // dispatch(getAllBannerHome());
  }, []);
  const { homeData } = useSelector(homeBanner);
  const handleBack = () => {
    document.body.style.overflow = "unset";
    navigation("/");
  };
  return (
    <div>
      <HeaderFrame
        footer=""
        iconBack={BACK}
        title="Thương hiệu"
        handleBack={handleBack}
      >
        <div className="grid grid-cols-2 gap-6 mb-6 mt-5">
          {homeData.categories?.map((res: ListBrands) => (
            <Fragment key={res.category_id}>
              <ItemBrand
                category_image_url={res.category_image_url}
                category_name={res.category_name}
                category_id={res.category_id}
              />
            </Fragment>
          ))}
        </div>
      </HeaderFrame>
    </div>
  );
}
