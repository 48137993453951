import GAP from "../../../assets/image/Game/Pickup/gap.png";
import DAY_GAP from "../../../assets/image/Game/Pickup/day-gap.png";

export default function OpenActiveCatcher({ pre_number }: any) {
  return (
    <div
      className={
        pre_number === "no"
          ? "relative day-gap-1"
          : pre_number === "left"
          ? "relative day-gap-1 ani-tranx-left"
          : "relative day-gap-1 ani-tranx-right"
      }
    >
      <img src={DAY_GAP} loading="lazy" className="relative day-gap z-0" />
      <div className="rotate-gap">
        <img src={GAP} loading="lazy" className="gap" />
      </div>
    </div>
  );
}
