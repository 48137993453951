import { useNavigate } from "react-router-dom";
import { BrandNews } from "../../../models/HomePage";

export default function ItemTabHome({ itemNews }: { itemNews: any }) {
  console.log(itemNews);
  const navigation = useNavigate();
  const handleNewsDetails = (e: string) => {
    navigation(`/details-news/${e}`);
  };
  return (
    <div>
      {itemNews?.map((res: BrandNews, index: number) => (
        <div
          onClick={() => handleNewsDetails(res?.id)}
          className="p-2 shadow-item rounded-2xl my-3"
          key={index}
        >
          <div className="grid grid-cols-12">
            <div className="col-span-3">
              <img src={res?.mini_banner_image_url} className="rounded-xl" />
            </div>
            <div className="col-span-9 px-2 flex flex-col justify-between">
              <div className="uppercase font-semibold-so leading-[20px] text-reponsive">
                {res.name}
              </div>
              <div className="font-light-so text-[#8A8A8A] text-[12px] leading-[16px] text-reponsive">
                {res.short_content}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
