import { useState, ChangeEvent } from "react";
import "../../assets/css/MenuTab.css";
interface Props {
  current: string;
  nameLeft: string;
  nameRight: string;
  handleClick: (
    e: React.MouseEvent<HTMLButtonElement>,
    status_gifts: string
  ) => void;
}
export default function MenuTab({
  current,
  handleClick,
  nameLeft,
  nameRight,
}: Props) {
  return (
    <div className="relative top-0">
      <div>
        <div className="tab bg-[#FFFFFF] flex justify-between items-center align-middle">
          <div className="flex justify-center w-40 flex-auto">
            <button
              key="1"
              id={"1"}
              disabled={current === "1"}
              onClick={(e) => handleClick(e, "quotation")}
              className="font-semibold-mon leading-4 mt-[8px] text-[11px] "
            >
              {nameLeft}
            </button>
          </div>
          <div className="flex justify-center w-40 flex-auto">
            <button
              key="2"
              id={"2"}
              disabled={current === "2"}
              onClick={(e) => handleClick(e, "done")}
              className="font-semibold-mon leading-4 mt-[8px] text-[12px]"
            >
              {" "}
              {nameRight}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
