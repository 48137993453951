/* eslint-disable */
import React, { useState, useRef } from "react";

type PatchedUIEvent = React.UIEvent & { deltaY: number };

interface SliderChangeEvent {
  value: number;
  position: number;
}

interface Props {
  style?: {};
  trackStyle?: {};
  thumbStyle?: {};
  progressStyle?: {};
  min?: number;
  max?: number;
  steps?: number;
  Thumb?: React.FC;
  Progress?: React.FC;
  Track?: React.FC;
  value?: number;
  onChange?(arg0: SliderChangeEvent): any;
  showProgress?: boolean;
}

const RangeSlider: React.FC<Props> = ({
  style,
  trackStyle,
  thumbStyle,
  min,
  max,
  steps,
  Thumb,
  value,
  onChange,
  showProgress,
  progressStyle,
  Progress,
}) => {
  const sliderRef = useRef<any>(null);
  const thumbRef = useRef<any>(null);

  //Setup values
  min = min ? min : 0;
  max = max ? max : 100;
  const defaultValue: number = (max - min) / 2;
  steps = steps ? steps : max - min;
  const stepSize = 100 / steps;
  onChange = onChange ? onChange : () => {};

  const [thumbPos, setThumbPos] = useState<number>(
    ((defaultValue - min) / (max - min)) * 100
  );

  if (value) {
    let newPos = ((value - min) / (max - min)) * 100;
    if (thumbPos !== newPos && newPos > 0 && newPos <= 100) {
      setCleanThumbPos(newPos);
    }
  }

  const sliderStyle: {} = { cursor: "pointer", ...style, position: "relative" };

  function handleDown(e: React.SyntheticEvent) {
    e.preventDefault();
    window.addEventListener("pointerup", handleUp);
    window.addEventListener("pointermove", handleMove);
    moveThumbByMousePos(e);
  }
  function handleUp() {
    window.removeEventListener("pointerup", handleUp);
    window.removeEventListener("pointermove", handleMove);
  }
  function handleMove(e: PointerEvent) {
    moveThumbByMousePos(e);
  }
  function handleWheel(e: PatchedUIEvent) {
    e.preventDefault();
    if (e.deltaY !== 0) {
      let newPos = thumbPos + Math.sign(-e.deltaY) * stepSize;
      setCleanThumbPos(newPos);
    }
  }
  function handleFocus(e: React.FocusEvent) {
    disableScroll();
  }
  function handleBlur(e: React.FocusEvent) {
    enableScroll();
  }
  function handleMouseLeave(e: React.MouseEvent) {
    enableScroll();
  }
  function handleMouseEnter(e: React.MouseEvent) {
    disableScroll();
  }
  function handleKeyDown(e: KeyboardEvent | React.KeyboardEvent) {
    switch (e.keyCode) {
      case 37:
        //Left
        setCleanThumbPos(thumbPos - stepSize);
        break;
      case 40:
        //Down
        setCleanThumbPos(thumbPos - stepSize);
        break;
      case 38:
        //Up
        setCleanThumbPos(thumbPos + stepSize);
        break;
      case 39:
        //Right
        setCleanThumbPos(thumbPos + stepSize);
        break;
      default:
    }
  }

  function disableScroll() {
    // Get the current page scroll position
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;

    // if any scroll is attempted, set this to the previous value
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };
  }
  function enableScroll() {
    window.onscroll = function () {};
  }

  function setCleanThumbPos(newPos: number) {
    const cleanedPos = newPos > 100 ? 100 : newPos < 0 ? 0 : newPos;
    // const newVal = (cleanedPos / 100) * (max - min) + min;
    // if (onChange) {
    //   onChange({ value: newVal, position: cleanedPos });
    // }
    setThumbPos(newPos > 100 ? 100 : newPos < 0 ? 0 : newPos);
  }

  function moveThumbByMousePos(e: React.SyntheticEvent & any) {
    if (sliderRef.current) {
      let newPos =
        ((e.clientX - sliderRef.current.getBoundingClientRect().x) /
          sliderRef.current.getBoundingClientRect().width) *
        100;
      const remainder = newPos % stepSize;
      if (remainder !== 0) {
        if (remainder / stepSize < 0.5) {
          newPos -= newPos % stepSize;
        } else {
          newPos += stepSize - remainder;
        }
      }
      setCleanThumbPos(newPos);
      if (thumbRef.current) {
        thumbRef.current.focus();
      }
    }
  }

  return (
    <div
      ref={sliderRef}
      key="slider"
      style={sliderStyle}
      onPointerDown={handleDown}
      onWheel={handleWheel}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
    >
      <div
        ref={thumbRef}
        tabIndex={0}
        key="thumbParent"
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{
          position: "absolute",
          top: "50%",
          left: `${thumbPos}%`,
          transform: "translate(-50%, -50%)",
          zIndex: 2,
        }}
      >
        {Thumb && <Thumb key="thumb" />}
        {!Thumb && (
          <div
            style={
              thumbStyle
                ? thumbStyle
                : {
                    width: 10,
                    height: 10,
                    border: "2px solid black",
                    borderRadius: "100%",
                    backgroundColor: "white",
                  }
            }
          ></div>
        )}
      </div>
      {showProgress && (
        <div
          key="progressParent"
          style={{
            width: `${thumbPos}%`,
            position: "absolute",
            top: "50%",
            transform: "translate(0%, -50%)",
            zIndex: 1,
            overflow: "hidden",
          }}
        >
          {Progress && <Progress />}
          {!Progress && (
            <div
              key="progess"
              style={
                progressStyle
                  ? progressStyle
                  : {
                      width: "100%",
                      height: 10,
                      backgroundColor: "red",
                    }
              }
            ></div>
          )}
        </div>
      )}
      <div
        key="trackParent"
        style={{
          width: "100%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div
          key="track"
          style={
            trackStyle
              ? trackStyle
              : {
                  width: "100%",
                  height: 6,
                  backgroundColor: "#EEEEEE",
                  borderRadius: 15,
                }
          }
        ></div>
      </div>
      {/*<div key="track" style={trackStyle}></div>*/}
    </div>
  );
};

export default RangeSlider;

function CleanTrackStyle(trackStyle: {} | undefined) {
  if (trackStyle) {
    //Inject necessary styling
    trackStyle = {
      ...trackStyle,
      width: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
  } else {
    //create stlying
    trackStyle = {
      width: "100%",
      height: 5,
      backgroundColor: "grey",
      borderRadius: 5,

      //Center the track
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
  }
  return trackStyle;
}
