export const formatCoin = (data: any) => {
  return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
function getRandomItem(arr: any) {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function generateRandomDuplicateList(
  originalList: any,
  targetLength: number
) {
  let duplicatedList = [...originalList]; // Start with the original items
  while (duplicatedList.length < targetLength) {
    duplicatedList.push(getRandomItem(originalList)); // Randomly pick and add items
  }
  return duplicatedList;
}
export function formatDate(date: any) {
  const year = date?.getFullYear();
  const month = String(date?.getMonth() + 1).padStart(2, "0"); // Tháng từ 0-11 nên cần +1
  const day = String(date?.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
export const MaskedPhoneNumber = (phoneNumber: string | undefined) => {
  // Lấy 4 số cuối của số điện thoại
  const visiblePart = phoneNumber?.slice(-4);

  // Tạo chuỗi mới với 6 số đầu bị ẩn
  const maskedPhoneNumber = `XXXXXX${visiblePart}`;

  return maskedPhoneNumber;
};
