import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Pagination } from "antd";
import BACK from "../../assets/image/Header/back-header.png";
import WeekLeaderBoard from "./WeekLeaderBoard";
import { LeaderBoardServices } from "../../services/apiService/LeaderBorad";
import HeaderFrameLeaderBoard from "../../components/HeaderFrame/HeaderFrameLeaderBoard";

export default function ListPrizeBySeasons() {
  const navigation = useNavigate();
  const { id, type } = useParams();
  const [page, setPage] = useState<number>(1);
  const [current, setCurrent] = useState("1");
  const [typeGift, setDataTypeGift] = useState("week");
  const [totalPrize, setTotalPrize] = useState<number>(0);
  const [listBoard, setListBoard] = useState<any>();
  const handleD = (data: number) => {
    setPage(data);
  };
  const handleBack = () => {
    navigation(`/prize-game/${id}`);
  };
  const hanldeClick = (e: any, status_gifts: boolean) => {
    setCurrent(e.target.id);
    if (status_gifts) {
      setDataTypeGift("week");
      getPrizeCampaign(id as string, "week");
    } else {
      setDataTypeGift("month");
      getPrizeCampaign(id as string, "month");
    }
  };
  const getPrizeCampaign = (id: string, race_type: string) => {
    LeaderBoardServices.Race(id as string, race_type)
      .then((res: any) => {
        setListBoard(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getPrizeCampaign(id as string, "week");
    setCurrent("1");
  }, []);

  return (
    <div>
      <HeaderFrameLeaderBoard
        title="Danh sách trúng thưởng"
        handleBack={handleBack}
        iconBack={BACK}
        footer=""
        changeBackgroundColor="bg-[#DEFAFD]"
      >
        <div className="mt-2">
          <div className="">
            <div className="tab-leaderboard flex justify-between items-center align-middle mt-5">
              <div className="flex justify-center flex-auto">
                <button
                  key="1"
                  id={"1"}
                  disabled={current === `${1}`}
                  onClick={(e) => hanldeClick(e, true)}
                  className="font-semibold-mon mt-[6px] leading-4"
                >
                  Tuần
                </button>
              </div>
              <div className="flex justify-center flex-auto">
                <button
                  key="2"
                  id={"2"}
                  disabled={current === `${2}`}
                  onClick={(e) => hanldeClick(e, false)}
                  className="font-semibold-mon mt-[6px] leading-4"
                >
                  Tháng
                </button>
              </div>
            </div>
            <div className="box-border mt-3">
              <WeekLeaderBoard listBoard={listBoard} typeGift={typeGift} />
            </div>
            {current === "2" ? (
              <div className="flex justify-center mt-2">
                {totalPrize > 10 && (
                  <Pagination
                    defaultCurrent={page}
                    total={totalPrize}
                    onChange={handleD}
                  />
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </HeaderFrameLeaderBoard>
    </div>
  );
}
