export default function SkelatonHistory() {
  return (
    <div className="mb-5 bg-[#e8e7e7] rounded-xl animate-pulse">
      <div className="shadow-history grid grid-cols-12 rounded-2xl py-3 px-2">
        <div className="col-span-3 flex justify-center items-center">
          <div className="w-[80px] h-[80px] flex justify-center">
            <div className="w-[80px] h-[80px] object-cover rounded-lg bg-[#d6d6d6]"></div>
          </div>
        </div>

        <div className="col-span-9 flex flex-col justify-between">
          <div className="text-[12px] font-light-so px-1">
            <div className="flex justify-between bg-[#d6d6d6] w-full h-5 rounded-xl"></div>
            <div className="flex justify-between bg-[#d6d6d6] w-10/12 h-5 rounded-xl mt-1"></div>
            <div className="flex justify-between bg-[#d6d6d6] w-6/12 h-5 rounded-xl mt-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
