import { useEffect, useState } from "react";
import HEADER_DETAILS from "../../assets/image/Header/header-details.png";
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme CSS file

import { formatDate } from "../../utils/formatData";
import HOME from "../../assets/image/LeaderBoard/Home.png";
import { useNavigate } from "react-router-dom";
export default function HeaderFrameLeaderBoard({
  handleBack,
  children,
  iconBack,
  title,
  footer,
  iconfilter,
  setStartDate,
  setEndDate,
  changeBackgroundColor,
}: {
  handleBack: () => void;
  children: React.ReactNode;
  iconBack: string;
  title: string;
  footer: string;
  iconfilter?: any;
  setStartDate?: any;
  setEndDate?: any;
  changeBackgroundColor?: string;
}) {
  const navigation = useNavigate();
  const [selected, setSelected] = useState<any>(null);
  useEffect(() => {
    const start = formatDate(selected?.from);
    const end = formatDate(selected?.to);
    if (
      start !== "undefined-NaN-undefined" &&
      end !== "undefined-NaN-undefined"
    ) {
      setStartDate(start || "");
      setEndDate(end || "");
    }
  }, [selected]);
  const handleHome = () => {
    navigation("/");
  };
  return (
    <div>
      <div>
        <div>
          <div className="w-full h-20 bg-[#5BC2DC]">
            <img src={HEADER_DETAILS} />
          </div>
          <div
            className={`${
              changeBackgroundColor ? changeBackgroundColor : "bg-[#fff]"
            } containerNotify__background  absolute rounded-[30px_30px_0_0] top-6 h-[96%] w-full z-10`}
          >
            <ul
              className={`${
                changeBackgroundColor ? changeBackgroundColor : "bg-[#fff]"
              } containerNotify__background-list pt-3 box-border relative z-30 px-3 h-full`}
            >
              <div className="relative z-20">
                <div>
                  <img
                    src={iconBack}
                    className="w-9 relative z-10"
                    onClick={handleBack}
                  />
                </div>
                <div className="absolute w-full -mt-1 text-center text-[20px] top-1 left-1/2 -translate-x-1/2 text-[#2D4E68] font-semibold-so">
                  <div className="mt-1">{title}</div>
                </div>
                <div
                  className="w-9 absolute top-0 right-0 z-40"
                  onClick={handleHome}
                >
                  <img src={HOME} />
                </div>
                <div>{children}</div>
              </div>
            </ul>
            <div className="fixed -bottom-6 z-30 overflow-hidden w-full">
              <img src={footer} className="w-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
