import { useEffect } from "react";
import PopupOverlay from "../components/BackgroundPopup/PopupOverLay";

export default function PopupLogin({
  isShow,
  children,
}: {
  isShow: boolean;
  children: React.ReactNode;
}) {
  // useEffect(() => {
  //   if (isShow) {
  //     document.body.classList.add("overflow-hidden");
  //   } else {
  //     document.body.classList.remove("overflow-hidden");
  //   }
  //   return () => {
  //     document.body.classList.remove("overflow-hidden");
  //   };
  // }, [isShow]);

  // if (!isShow) return null;
  return (
    <PopupOverlay isShow={isShow}>
      <div className="px-5">
        <div className="bg-login font-light-so">{children}</div>
      </div>
    </PopupOverlay>
  );
}
