import { ListGiftCustomer } from "../../models/ListReceiveCustomer";

export default function TabGift({
  item,
  key,
  index,
}: {
  item: ListGiftCustomer;
  key: any;
  index: number;
}) {
  return (
    <>
      <tr
        className="h-12"
        style={
          index % 2 === 0
            ? { backgroundColor: "#F2F5F9", border: "1px solid #ffffff" }
            : { backgroundColor: "#ffffff" }
        }
      >
        <td id="gift" className="font-regular-mon pl-1 w-[150px]">
          {item.gift_name}
        </td>
        <td
          id="gift"
          className="border-0 flex justify-center items-center mt-1"
        >
          <div className="border-table-listgift ml-0 font-semibold-so text-[16px] flex items-center justify-end">
            {" "}
            {item.plan}
          </div>
        </td>
        <td id="gift" className="pl-2">
          <div
            className="border-table-listwhitegift font-semibold-so text-[16px] flex items-center justify-end"
            style={
              index % 2 === 0
                ? { backgroundColor: "#ffffff" }
                : { backgroundColor: "#ffffff" }
            }
          >
            {item.dropped}
          </div>
        </td>
        <td id="gift" className="pl-2">
          <div
            className="border-table-listwhitegift font-semibold-so text-[16px] flex items-center justify-end"
            style={
              index % 2 === 0
                ? { backgroundColor: "#ffffff" }
                : { backgroundColor: "#ffffff" }
            }
          >
            {item.remaining}
          </div>
        </td>
      </tr>
    </>
  );
}
